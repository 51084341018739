import React, { useState } from "react";
import { IconLink, IconLoad, IconEducator } from "@Knowledge-OTP/znk-ui-components";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { SET_DATA_MODAL_FORM_LIST } from "../../../../store/modal/const";
import useTranslation from "../../../../i18n/useTranslation";
import { hasHttp } from "../utils";
import useLinkLesson from "../InfoLesson/useLinkLesson";
import SendLessonAttendance from "../../../../Model/Lessons/SendLessonAttendance";
import { showAlert } from "../../../../common/Alert/util";
import useCopyText from "../../../../common/CopyText/useCopyText";
import OrientationLessonModal from "./OrientationLessonModal";
import AddSustituteEducator from "../AddSustituteEducatorForOrientation";
import AddSubstitute from "../../../../Model/Lessons/AddSubstitute";

const OrientationModal = (props) => {
  const { refetch } = props;
  //redux modal para lecciones de orientación
  const reduxOrientation = useSelector(
    (state) => state.modal.plan.orientationLessonModal
  );
  const {
    isOpen: isOpenOrientation,
    dataLesson: dataLessonOrientation,
  } = reduxOrientation;
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const [backupLoad, setBackupLoad] = useState(false);
  const getvclink = useLinkLesson();
  const [goToClassLoad, setGoToClassLoad] = useState(false);

  const setDataLessonToRedux = (newDataLesson) => {
    // setea lección modificada en redux, se usa luego de ejecutar una mutación
    dispatch({
      type: "SET_DATA_MODAL_FORM_LIST",
      payload: "plan.orientationLessonModal",
      data: {
        isOpen: true,
        dataLesson: newDataLesson,
      },
    });
  };
  //set student/educator attendance
  const [
    setAttendanceMutation,
    { loading: loadAttendanceMutation },
  ] = SendLessonAttendance(
    ({ data }) => {
      setDataLessonToRedux(data?.sendLessonAttendance);
      //refetchCalendarQuery()
    },
    (error) => {
      console.error(error);
    }
  );
  const [substituteEducator, setSubstituteEducator] = useState(null) 
  const { submit: addSubstituteMutation, loading: loadAddSubstituteMutation } = AddSubstitute(
    (res) => {
      refetch()
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.addSustituteEducatorForOrientation',
        data: {
          isOpen: false,
          setEducator: () => {},
          educator: {}
        }
      })
      setSubstituteEducator(res?.data?.addSubstitute?.substitute || null)
    }, (error) => {
      console.error(error)
      alert(error)
    })

  const [loadCopy, setLoadCopy] = useState(false);
  const [copyText, setCopyText] = useCopyText();
  const studentOptions = (student) => {
    const [copySuccess, copyError] = [
      (text) => {
        //success
        showAlert(
          { text: translate("modal.infolesson.copy"), status: "success" },
          dispatch
        );
        showAlert(
          {
            text: `Link: ${text}`,
            status: "success",
            onClick: () => {
              window.open(text, "_blank");
            },
          },
          dispatch
        );
        setLoadCopy(false);
      },
      (error, text) => {
        //error
        console.error(error);
        showAlert(
          {
            text: `${translate("modal.infolesson.copyError")} \n ${copyText}`,
            status: "error",
          },
          dispatch
        );
        showAlert(
          {
            text: `Link: ${text}`,
            status: "success",
            onClick: () => {
              window.open(text, "_blank");
            },
          },
          dispatch
        );
        setLoadCopy(false);
      },
    ];
    return [
      {
        component: (
          <span
            onClick={() => {
              if (!loadCopy) {
                setLoadCopy(true);
                // si location es VCR es launchURL, sino es location.linkStudent!
                getvclink(
                  dataLessonOrientation?.id,
                  student?.studentUserId || student?.id
                )
                  .then(
                    ({ launchUrl, location }) => {
                      if (location.type === "virtualClassroom") {
                        //navigator.clipboard.writeText(hasHttp(launchUrl))
                        //.then(copySuccess).catch(copyError)
                        setCopyText(
                          hasHttp(
                            launchUrl || dataLessonOrientation.backupLink
                          ),
                          copySuccess,
                          copyError
                        );
                      } else
                        setCopyText(
                          hasHttp(
                            location.linkStudent ||
                              dataLessonOrientation.backupLink
                          ),
                          copySuccess,
                          copyError
                        ); //navigator.clipboard.writeText(hasHttp(location.linkStudent))
                    },
                    (error) => {
                      console.error(error);
                      //navigator.clipboard.writeText(hasHttp(dataLesson.location.linkStudent))
                      //.then(copySuccess).catch(copyError)
                      setCopyText(
                        hasHttp(
                          dataLessonOrientation.location.linkStudent ||
                            dataLessonOrientation.backupLink
                        ),
                        copySuccess,
                        copyError
                      );
                    }
                  )
                  .catch((error) => {
                    console.error(error);
                    //navigator.clipboard.writeText(hasHttp(dataLesson.location.linkStudent))
                    //.then(copySuccess).catch(copyError)
                    setCopyText(
                      hasHttp(
                        dataLessonOrientation.location.linkStudent ||
                          dataLessonOrientation.backupLink
                      ),
                      copySuccess,
                      copyError
                    );
                  });
              }
            }}
          >
            {loadCopy ? (
              <IconLoad className={"mr-2"} />
            ) : (
              <IconLink className={"mr-2"} />
            )}{" "}
            {translate("modal.infolesson.copylocationlink")}
          </span>
        ),
      },
    ];
  };

  const educatorOptions = (educator) => {
    const [copySuccess, copyError] = [
      (text) => {
        //success
        showAlert(
          { text: `${translate("modal.infolesson.copy")}`, status: "success" },
          dispatch
        );
        showAlert(
          {
            text: `Link: ${text}`,
            status: "success",
            onClick: () => {
              window.open(text, "_blank");
            },
          },
          dispatch
        );
        setLoadCopy(false);
      },
      (error, text) => {
        //error
        console.error(error);
        showAlert(
          {
            text: `${translate("modal.infolesson.copyError")}`,
            status: "error",
          },
          dispatch
        );
        showAlert(
          {
            text: `Link: ${text}`,
            status: "success",
            onClick: () => {
              window.open(text, "_blank");
            },
          },
          dispatch
        );
        setLoadCopy(false);
      },
    ];
    return [
      {
        component: (
          <>
            <span
              onClick={() => {
                if (!loadCopy) {
                  setLoadCopy(true);
                  // si location es VCR es launchURL, sino es location.linkEducator!
                  getvclink(
                    dataLessonOrientation?.id,
                    educator?.educatorUserId || educator?.id
                  )
                    .then(
                      ({ launchUrl, location }) => {
                        if (location.type === "virtualClassroom") {
                          //navigator.clipboard.writeText(hasHttp(
                          //  launchUrl
                          //))
                          //.then(copySuccess).catch(copyError)
                          setCopyText(
                            hasHttp(
                              launchUrl || dataLessonOrientation.backupLink
                            ),
                            copySuccess,
                            copyError
                          );
                        } else {
                          setCopyText(
                            hasHttp(
                              location.linkEducator ||
                                dataLessonOrientation.backupLink
                            ),
                            copySuccess,
                            copyError
                          );
                          //navigator.clipboard.writeText(hasHttp(location.linkEducator))
                          //  .then(copySuccess).catch(copyError)
                        }
                      },
                      (error) => {
                        console.error(error);
                        //navigator.clipboard.writeText(hasHttp(dataLesson.location.linkEducator))
                        //.then(copySuccess).catch(copyError)
                        setCopyText(
                          hasHttp(
                            dataLessonOrientation.location.linkEducator ||
                              dataLessonOrientation.backupLink
                          ),
                          copySuccess,
                          copyError
                        );
                      }
                    )
                    .catch((error) => {
                      console.error(error);
                      setCopyText(
                        hasHttp(
                          dataLessonOrientation.location.linkEducator ||
                            dataLessonOrientation.backupLink
                        ),
                        copySuccess,
                        copyError
                      );
                    });
                }
              }}
            >
              {loadCopy ? (
                <IconLoad className={"mr-2"} />
              ) : (
                <IconLink className={"mr-2"} />
              )}{" "}
              {translate("modal.infolesson.copylocationlink")}
            </span>
            <span
              onClick={() => {
                dispatch({
                  type: 'SET_DATA_MODAL_FORM_LIST',
                  payload: 'plan.addSustituteEducatorForOrientation',
                  data: {
                    isOpen: true,
                    setEducator: (edu) => {
                      addSubstituteMutation({
                        educatorUserId: edu?.id,
                        lessonId: dataLessonOrientation?.id,
                      });
                    },
                    educator: educator,
                  },
                });
              }}
            >
              <IconEducator className={"mr-2"} />{" "}
              {translate("modal.infolesson.addsubstitute")}

            </span>
          </>
        ),
      },
    ];
  };

  return (
      <>
    <OrientationLessonModal
      refetch={refetch}
      role={"admin"}
      isOpen={isOpenOrientation}
      toggle={() => {
        dispatch({
          type: SET_DATA_MODAL_FORM_LIST,
          payload: "plan.orientationLessonModal",
          data: {
            ...reduxOrientation,
            isOpen: !isOpenOrientation,
          },
        });
      }}
      dataLesson={dataLessonOrientation}
      onRescheduled={(dataLesson) => {
        dispatch({
          type: "SET_DATA_MODAL_FORM_LIST",
          payload: "lesson.rescheduledLesson",
          data: {
            isOpen: true,
            dataLesson: dataLesson,
          },
        });
      }}
      onCheckIn={() => {}}
      onCheckInLoad={false}
      onCancel={(dataLesson) => {
        dispatch({
          type: "SET_DATA_MODAL_FORM_LIST",
          payload: "plan.cancelLesson",
          data: {
            isOpen: true,
            lessonId: dataLesson?.id || "",
            isOrientation: dataLesson?.isMeeting ? false : true,
            isMeeting: dataLesson?.isMeeting,
            refetch: refetch,
          },
        });
      }}
      cancelText={translate("modal.infolesson.youneedremove")}
      onGoToClass={(dataLesson) => {
        // si location es VCR es launchURL, sino es location.linkStudent!
        setGoToClassLoad(true);
        getvclink(dataLesson?.id, null)
          .then(
            ({ launchUrl, location }) => {
              if (location.type === "virtualClassroom")
                window.open(hasHttp(launchUrl), "_blank");
              else window.open(hasHttp(location.linkStudent), "_blank");
              setGoToClassLoad(false);
            },
            (error) => {
              console.error(error);
              //setVcLinkError(true)
              setGoToClassLoad(false);
            }
          )
          .catch((error) => {
            console.error(error);
            //setVcLinkError(true)
            setGoToClassLoad(false);
          });
      }}
      onGoToClassLoad={goToClassLoad}
      gotoclassText={"Go to classroom"}
      onBackUp={(dataLesson) => {
        setBackupLoad(true);
        window.open(hasHttp(dataLesson?.backupLink), "_blank");
        setBackupLoad(false);
      }}
      onBackUpLoad={backupLoad}
      backupText={"Backup call"}
      studentText={translate("modal.infolesson.student")}
      educatorText={translate("modal.infolesson.educator")}
      showCreatedDate={(createdDate) => {
        return (
          <>
            <b>{translate("modal.infolesson.created")}</b>{" "}
            {translate("modal.infolesson.at")}{" "}
            {`
                        ${createdDate &&
                          moment(createdDate).format("MMM DD, YYYY hh:mma")}
                        `}
          </>
        );
      }}
      showUpdatedDate={(updateDate) => {
        return (
          <>
            <b>{translate("modal.infolesson.update")}</b>{" "}
            {translate("modal.infolesson.at")}{" "}
            {`
                        ${updateDate &&
                          moment(updateDate).format("MMM DD, YYYY hh:mma")}
                        `}
          </>
        );
      }}
      onSaveAttendance={(dataLesson, studentAtt, educatorAtt) => {
        setAttendanceMutation({
          educatorAttendance: educatorAtt,
          attendance: Object.entries(studentAtt).map(([idStu, stuAtt]) => {
            return {
              studentId: idStu,
              status: stuAtt,
            };
          }),
          lessonId: dataLesson?.id || "",
        });
      }}
      saveAttendanceLoad={loadAttendanceMutation}
      onSendResume={(dataLesson) => {
        dispatch({
          type: SET_DATA_MODAL_FORM_LIST,
          payload: "plan.resumeOrientationModal",
          data: {
            isOpen: true,
            dataLesson: dataLesson,
          },
        });
      }}
      sendResumeText={"Send resume"}
      /**props for student and educator dropdown**/
      studentOptions={studentOptions}
      educatorOptions={educatorOptions}
      substituteEducator={substituteEducator}
      setSubstituteEducator={setSubstituteEducator}
    />
    <AddSustituteEducator loadMutation={loadAddSubstituteMutation} />
    </>
  );

};

export default OrientationModal;
