import moment from 'moment'
import { lastGradeEnum } from '../Modal/newPlanModal/Sections/Student/grades'

export const getValues = (date, type) => {
    const month = moment(date).month()
    let year = moment(date).year()

  // Ajustar el año académico
  if (month >= 6) { // Si el mes es de julio en adelante
      year += 1;
  }
    const difY = year - moment().year()

    switch (type) {
      case 'class':
        return year.toString().substring(2, 4)// Obtener el último par de dígitos del año
      default:
          // Ajuste para que el ciclo académico y el grado se calculen correctamente
        return lastGradeEnum - difY;
  }
}


export default {
    getValues
}