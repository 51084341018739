import { useQuery, gql } from "@apollo/client"

const useStudentsCheckIn = (vars = {}, options = {fetchPolicy: 'no-cache'}) => {
    const userQuery = `
        id
        firstName
        lastName
        avatar
        studentInfo {
            parents {
                parentUserId
                managePayments
                manageApprovals
                firstName
                lastName
            }
        }
    `
    return useQuery(
        gql`
            query {
                plansCheckIns {
                    checkinPlans {
                        ${userQuery}
                        planId
                        planName
                        checkInDate {
                            id
                            date
                            type
                        }
                    }
                    general {
                        ${userQuery}
                        tasksForWidget {
                            id
                            title
                            educator {
                                firstName
                                lastName
                            }
                            substitute {
                                firstName
                                lastName
                            }
                            endDate
                        }
                    }
                }
            }
        `,{
            variables: vars,
            ...options
        }
    )
}

export default useStudentsCheckIn