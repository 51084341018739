import moment from 'moment'
import filterNames from "./filtersNames"

export const showFiltersList = (list) => {
    console.log('list', list)
    if (typeof list !== 'object') return []
    const filtersList = []
    // eslint-disable-next-line
    for (let key in list) {

            if (Array.isArray(list[key]) && list[key]?.length > 0 && !key.includes('Ids')) {
                filtersList.push({filterName: filterNames[key], filterValues: list[key].join(', ')})
            } else if (Array.isArray(list[key]) && list[key]?.length > 0 && key.includes('Ids')) {
                filtersList.push({filterName: filterNames[key], filterValues: `${list[key].length} selected`})
            }

            if (typeof list[key] === 'string' && list[key] !== '' && list[key] !== null) {
                filtersList.push({filterName: filterNames[key], 
                    filterValues: key === 'testDate' ? moment(list[key]).format('MMMM Do, YYYY') : filterNames[list[key]] || list[key]})
            }
    }

    return filtersList
}