import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { useCallback } from 'react';
import myUniversity from '../../Model/queryResults/myUniversity';

export default (onSuccess, onError = () => {}) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`
            mutation(
                $userId: ID
                $universityIds: [String!]!
                $charge: Boolean
                $planId: ID
            ){
                setMultiUniversities (
                    universityIds: $universityIds
                    userId: $userId
                    charge: $charge
                    planId: $planId
                ){
                    ${myUniversity}
                }
            }
        `
  );
  const submit = useCallback(
    (obj) => {
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return { loading, data: data ? data.setMultiUniversities : null, error, submit };
};
