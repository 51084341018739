
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  IconRemove,
  IconRight,
  ListItem,
  ConfirmModal,
  IconStar,
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import useMarkMyUniversity from '../../Model/useMarkMyUniversity'
import useRemoveMyUniversity from '../../Model/useRemoveMyUniversity'
import useMyCollegeModal from '../useMyCollegeModal'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import MessageModal from '../Components/messageModal'
import EarlyDecisionItem from '../Components/EarlyDecisionItem'
import EarlyActionItem from '../Components/EarlyActionItem'
import EarlyActionStatus from '../Components/EarlyStatus/EarlyAction'
import EarlyDecisionStatus from '../Components/EarlyStatus/EarlyDecision'
import earlyDecisionNames from '../../Utils/earlyDecision'

const SummaryTab = (props) => {
  let { college/**, data, loading*/, setIsMyCollege} = props
  // const { translate } = useTranslation()
  const [activeTab, setActiveTab] = useState('status')
  const [removeUniversity, {loading: loadRemove, data: dataRemoveUniversity}] = useRemoveMyUniversity()
  const [markUniversity, {loading: loadMark, data: dataMarkUniversity}] = useMarkMyUniversity(
    () => {
      onChange()
    },
    (err) => {console.error(err?.toString())}
  )
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  // eslint-disable-next-line
  const [message, setMessage] = useState('')
  //El texto del body no está definido en los diseños
  const [bodyModal, setBodyModal] = useState('')
  //Textos y variables para pasarle al modal
  const [titleModal, setTitleModal] = useState('')
  const [confirmButton, setConfirmButton] = useState('')
  const [variables, setVariables] = useState({})
  // eslint-disable-next-line
  let { onChange, userId, setUpdateUniversity, close: closeMyUniversityModal } = useMyCollegeModal()

  const reduxData = useSelector(
    (state) => state?.modal?.counseling.collegeModal,
  )

  const collegesList = reduxData?.universities

  const refetchUserData = useSelector((state) => state.user.refetch)
  const dispatch = useDispatch()

  const checkEarlyDecision = (edVersion) => {
    // Busca si hay ya una universidad marcada como early decision y si es distinta a la seleccionada
    const earlyDecisionCollege = collegesList?.filter(
      (x) => x.early === edVersion && x.id !== college?.id,
    )
    // Si otra está marcada como seleccionada:
    if (earlyDecisionCollege.length > 0) {
      setTitleModal(
        `${
          college?.early === edVersion ? 'Remove' : 'Mark'
        } ${earlyDecisionNames[edVersion]}`,
      )
      setConfirmButton(
        `Ok, ${
          college?.early === edVersion ? 'Remove from' : 'Mark as'
        } ${earlyDecisionNames[edVersion]}`,
      )
      setBodyModal(
        `You have another university as ${earlyDecisionNames[edVersion]}: ${earlyDecisionCollege[0]?.name}. Remove an mark ${college?.name}?`,
      )
      
      setVariables({
        ...variables,
        universityId: college?.id,
        mark: edVersion,
        action: 'mark',
        userId,
        makeValidation: true,
      })
      setConfirmModalOpen(true)
    }
    // Si no hay una universidad marcada como Early Decision o si la marcada es la seleccionada
    else {
      setTitleModal(
        `${
          college?.early === edVersion ? 'Remove' : 'Mark'
        } ${earlyDecisionNames[edVersion]}`,
      )
      setConfirmButton(
        `Ok, ${
          college?.early === edVersion ? 'Remove from' : 'Mark as'
        } ${earlyDecisionNames[edVersion]}`,
      )
      
      setVariables({
        ...variables,
        universityId: college?.id,
        mark: college?.early === edVersion ? 'nothing' : edVersion,
        action:
          college?.early === 'nothing' || 'action' ? 'mark' : 'unmark',
        userId  
      })
      setConfirmModalOpen(true)
    }
  }

  const confirmFunction = () => {
    if(titleModal === 'Remove University') {
      removeUniversity({variables: variables})
    } else {
      markUniversity({variables: variables})
    } 
  }

  const cancelFunction = () => {
    // Setea en string vacío los estados seteados al abrir el modal de confirmación
    setTitleModal('')
    setConfirmButton('')
    setBodyModal('')
    setVariables({
      universityId: '',
      mark: '',
      action: '',
    })
    setConfirmModalOpen(false)
  }

  useEffect(() => {
    setVariables({...variables, userId})
    // eslint-disable-next-line
  }, [userId])

  useEffect(() => {
    if (dataRemoveUniversity) {
      refetchUserData()
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling.collegeModal',
        data: {
          ...reduxData,
          universities: dataRemoveUniversity?.removeMyUniversity?.studentInfo?.universities}
      })
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling.univeristyDirectory',
        data: {
          universities: dataRemoveUniversity?.removeMyUniversity?.studentInfo?.universities
        }
      })
      setIsMyCollege(false)
      if (onChange) onChange()
      setUpdateUniversity({...college, early: null, status: null})
      setConfirmModalOpen(false)
    }
  // eslint-disable-next-line
  }, [dataRemoveUniversity])

  useEffect(() => {
    if (dataMarkUniversity) {
      refetchUserData()
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling.collegeModal',
        data: {
          ...reduxData,
          universities: dataMarkUniversity?.markMyUniversity?.studentInfo?.universities}
      })
      const collegeToUpdate = dataMarkUniversity?.markMyUniversity?.studentInfo?.universities.find(
        (c) => c.id === college?.id,
      )
      if (collegeToUpdate){
        setUpdateUniversity({...college, early: collegeToUpdate?.early, status: collegeToUpdate?.status})
      }
      setConfirmModalOpen(false)
      setMessageModalOpen(false)
    }
  // eslint-disable-next-line
  }, [dataMarkUniversity])
  
  return (

    <div className='d-flex flex-column w-100'>
      <div className='d-flex flex-row w-100'>

        <Nav className='znk-scroll-horizontal ml-2 mb-3 flex-nowrap w-100 d-flex justify-content-between'>
        <NavItem
            className='m-0 p-0 h5 text-primary'
            active={activeTab === 'status'}
          >
          <NavLink
              className='nav-title m-0 p-0 h5 '
              active={activeTab === 'status'}
              style={{
                fontSize: '15px',
              }}
              onClick={() => {
                setActiveTab('status')
              }}
            >
              Application status
            </NavLink>
          </NavItem>
          <NavItem
            className='m-0 p-0 h5 text-primary'
            active={activeTab === 'steps'}
          >
          <NavLink
              className='nav-title m-0 p-0 h5'
              active={activeTab === 'steps'}
              style={{
                fontSize: '15px',
              }}
              onClick={() => {
                setActiveTab('steps')
              }}
            >
              View steps
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className='w-100 d-flex flex-row'>
        <TabContent activeTab={activeTab} className='px-2 w-100'>
          <TabPane tabId='status' className='w-100'>
            {/* Renderiza solo si está marcado como Early Action */}
            {college?.early === 'action' ?
              <EarlyActionStatus />
                : ['decision', 'decision2'].includes(college?.early) ?
                <EarlyDecisionStatus edVersion={college?.early}/>
                : null
            }
            <div
              title='The application process has not started yet'
              className='row d-flex flex-row justify-content-start py-2 w-100'
              style={{
                borderRadius: '10px',
                border: '2px dashed',
                borderColor: '#f0f2f5',
                marginLeft: '0.1rem',
              }}
            >
              <div className="col-1 mr-3 align-self-center">{' '} <IconStar color={'#d7e1ea'} size={25} /></div>
              <div className="col-10 align-self-center"><span className='d-flex justify-content-start h4 text-muted'
              >
                The application process has not started yet
              </span></div>
            </div>
            <div className='d-flex flex-column justify-content-around mt-5 h-40 w-100'>
              <EarlyActionItem 
                college={college}
                checkEarlyAction={() => {
                  setTitleModal(`${college?.early === 'action' ? 'Remove' : 'Mark'} Early Action`)
                  setConfirmButton(`Ok, ${college?.early === 'action' ? 'Remove from' : 'Mark as'} Early Action`)
                  
                  setVariables({
                    ...variables,
                    universityId: college?.id,
                    mark: college?.early === 'action' ? 'nothing' : 'action',
                    action: college?.early !== 'action' ? 'mark' : 'unmark',
                    userId
                  })
                  setConfirmModalOpen(true)
                }}
              />
              <EarlyDecisionItem
                college={college} 
                checkEarlyDecision={() => { checkEarlyDecision('decision')}}
                edVersion={'decision'}
              />
              <EarlyDecisionItem
                college={college} 
                checkEarlyDecision={() => { checkEarlyDecision('decision2')}}
                edVersion={'decision2'}
              />
              <ListItem
                className='w-100 ml-1 pr-3 pl-2'
                hasHover
                Left={
                  <div>
                    <IconRemove size={60} color='#ff3f1d' />
                    <span
                      className='ml-5 h4'
                      style={{
                        color: '#ff3f1d',
                      }}
                    >
                      Remove from My Universities
                    </span>
                  </div>
                }
                Right={<IconRight size={16} />}
                onClick={() => {
                  setTitleModal(`Remove University`)
                  setBodyModal('Do you want to remove your University from the list?')
                  setConfirmButton(`Ok, remove my University`)
                  setVariables({
                    ...variables,
                    universityId: college?.id
                  })
                  setConfirmModalOpen(true)
                  // removeUniversity({ variables: { universityId: college?.collegeUnitId } })        
                }}
              />
            </div>
          </TabPane>
          <TabPane tabId='steps'>
          </TabPane>

        </TabContent>
      </div>
      <ConfirmModal
        className='m-4'
        isOpen={confirmModalOpen}
        toggle={!confirmModalOpen}
        title={titleModal}
        body={bodyModal}
        loading={loadMark || loadRemove}
        classNameActionDiv={'d-flex flex-column justify-content-end w-80'}
        confirmAction={() => {
          
          confirmFunction()
        }}
        cancelAction={() => cancelFunction()}
        confirmText={
          confirmButton
        }
        cancelText={
          'Cancel'
        }
      />
            <MessageModal
        isOpen={messageModalOpen}
        toggle={() => setMessageModalOpen(!messageModalOpen)}
        message={message}
      />
    </div>

  )
}

export default SummaryTab