import React, { useState, useEffect } from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  Avatar,
  StickyButton,
  Activity,
  Input,
  IconSearch
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { gql, useLazyQuery } from '@apollo/client'

const AddSustituteEducator = (props) => {
  const { loadMutation } = props
  const modalData = useSelector(
    (state) => state.modal.plan.addSustituteEducatorForOrientation)
  const { isOpen, setEducator } = modalData
  const [selectEducator, setSelectEducator] = useState({})
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.addSustituteEducatorForOrientation',
      data: {
        ...modalData,
        isOpen: !isOpen
      }
    })
  }
  const { breakWidth } = useWindowDimensions()
  const [getEducators, { data, loading }] = useLazyQuery(
    gql`query ($role: Roles!) {
        allUsersFilter(role:$role) {
          docs {
            id
            firstName
            lastName
            isActive
            emails {
            address
            verified
            __typename
          }
        staffInfo {
          backupLink
          __typename
        }
          }
        }
    }`
  )
  useEffect(() => {
    getEducators({
      variables: {
        role: 'SUPER_ADMIN'
      }
    })
    // eslint-disable-next-line
  }, [])
  const educators = loading ? [] : (data?.allUsersFilter?.docs || [])
  const [educatorSearch, setEducatorSearch] = useState(null)
  return (
    <Modal
      buttonLabel={null}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header d-flex align-items-center pb-0 my-3'>
          <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('modal.addsubstitute.title')}</span>
          <IconClose size={20} onClick={toggle} />
        </div>
      }
    >
      <div className='p-0 m-4'>
        <Input
          className='w-100 input-xs p-0 mb-3'
          placeholder={`${translate('modal.newplan.selecteducator.search')} name`}
          addOnComponent={<IconSearch size={16} />}
          type='addon'
          onChange={(e) => {
            setEducatorSearch(e.target.value)
          }}
        />
        <div
          className='m-0 py-2 px-0' style={{
            maxHeight: '300px',
            overflow: 'hidden scroll'
          }}
        >
          {(loading && educators)
            ? <div className='d-flex justify-content-center'><Activity /></div>
            : educators.filter(
              (edu) => educatorSearch !== null && educatorSearch !== '' ? new RegExp(educatorSearch, 'i').exec(`${edu.firstName} ${edu.lastName}`) : true
            ).map((edu) => {
              return (
                <ListOption
                  key={edu.id}
                  className='mb-2'
                  id='x-0'
                  label={null}
                  selectedInput={selectEducator.id === edu.id}
                  childClassName='w-100'
                  icon={
                    <div className='d-flex align-items-center text-muted'>
                      <Avatar
                        name={`${edu.firstName} ${edu.lastName}`}
                        named={false}
                        uri={null}
                        size='avatar-small avatar-span-white'
                      />
                      <div className='d-flex justify-content-between w-100'>
                        <span className='ml-2 h4'>
                          {`${edu.firstName} ${edu.lastName}`}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => {
                    setSelectEducator(edu)
                  }}
                />
              )
            })}
        </div>
        <StickyButton
          activity={loadMutation}
          disabled={loadMutation}
          color='#696CFF'
          condition={JSON.stringify(selectEducator) !== JSON.stringify({})}
          label={translate('modal.addsubstitute.title')}
          onClickButtonFunction={() => {
            setEducator(selectEducator)
          }}
        />
      </div>
    </Modal>
  )
}

AddSustituteEducator.propTypes = {}
AddSustituteEducator.defaultProps = {}

export default AddSustituteEducator
