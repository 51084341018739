import React, { useEffect, useState } from 'react'
import { ModalLayout, IconSearch, Input, Button } from '@Knowledge-OTP/znk-ui-components'
import './index.css'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import { collegesMock } from './mocks/universitiesMock'
import useUniveristyDirectory from './useUniversityDirectory'
import useAllUniversities from '../Model/useAllUniversities'
import Pagination from '../../../common/Pagination'
import CompleteUniversityInfo from '../Utils/index'
import Skeleton from 'react-loading-skeleton'
import { TabContent, TabPane } from 'reactstrap'
import useMyCollegeModal from '../MyCollegeModal/useMyCollegeModal'
import useSetMultiUniversities from './Model/useSetMultiUniversities'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../store/modal/const'
import { showAlert } from '../../../common/Alert/util'
import useTranslation from '../../../i18n/useTranslation'

const UniversityDirectory = (props) => {

  // const {/**universities,*/ addUniversity } = props
  // eslint-disable-next-line
  const { selector = false, setSelected, selected, plan/** , onAddUniversity*/ } = props
  const {
    isOpen,
    userId,
    onChange: onAddUniversity,
    // open,
    // close: closeUniversityDirectory,
    toggle,
    universities,
    planId
  } = useUniveristyDirectory()
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const reduxData = useSelector(
    (state) => state.modal.counseling.univeristyDirectory,
  )
  const { refetch } = reduxData
  const { open: openMyCollegeModal } = useMyCollegeModal()
  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const onSelectMultiUniversities = (e, university) => {
    if (e.target.checked) {
      setSelectedUniversities((prev) => [...prev, university]);
    } else {
      setSelectedUniversities((prev) => prev.filter((item) => item.id !== university.id));
    }
  };

  const onSuccess = (result) => {
    showAlert({ text: 'Universities Saved Successfully', status: 'success' }, dispatch)
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.univeristyDirectory',
      data: {
        ...reduxData,
        isOpen: false,
        universities: result?.data?.setMultiUniversities?.studentInfo?.universities,
      }
    })
    onAddUniversity()
    refetch()
  }

  const onError = (e) => {
    showAlert({ text: `Error saving universities: ${e.message}`, status: 'error' }, dispatch)
  }
  useEffect(() => {
    if (universities) {
      setSelectedUniversities(universities)
    }
  }, [universities])

  const { submit: submitMultiUniversities, loading: loadinSetMultiUniversities, data: dataMultiUniversities } = useSetMultiUniversities(onSuccess, onError)
  const handleSetMultiUniversities = () => {
    const selectedUniversitiesIds = selectedUniversities.map((uni) => uni.id)
    submitMultiUniversities({
      userId,
      universityIds: selectedUniversitiesIds,
      charge: false,
      planId
    })
  }

  useEffect(() => {
    if (dataMultiUniversities) {
      setSelectedUniversities(dataMultiUniversities?.data?.setMultiUniversities?.studentInfo?.universities)
    }
  }, [dataMultiUniversities])

  const labelButtonSaveUniversities = translate('universityDirectory.saveUniversities')
  const [labelButtonUniversities, setLabelButtonUniversities] = useState(labelButtonSaveUniversities)
  const [colorButton, setColorButton] = useState('primary')
  const [textColor, setTextColor] = useState('text-white')
  const [style, setStyle] = useState({})
  const [disableForNoChanges, setDisableForNoChanges] = useState(false)

  useEffect(() => {
    if (selectedUniversities?.length === universities?.length) {
      const selectedUniversitiesIds = selectedUniversities.map((uni) => uni.id)
      const universitiesIds = universities.map((uni) => uni.id)
      if (selectedUniversitiesIds.every((id) => universitiesIds.includes(id))) {
        setDisableForNoChanges(true)
      } else {
        setDisableForNoChanges(false)
      }
    } else {
      setDisableForNoChanges(false)
    }
  }, [selectedUniversities, universities])
  useEffect(() => {
    if (selectedUniversities?.length > 10) {
      setLabelButtonUniversities(translate('universityDirectory.maxUniversities'))
      setColorButton('danger')
      setTextColor('text-red')
      setStyle({ color: 'red', opacity: 2, backgroundColor: '#f8d7da', borderColor: '#f5c2c7', cursor: 'not-allowed' })
    } else {
      setLabelButtonUniversities(labelButtonSaveUniversities)
      setColorButton('primary')
      setTextColor('text-white')
      setStyle({})
    }
    // eslint-disable-next-line
  }, [selectedUniversities])

  const [inputValue, setInputValue] = useState('')
  // Arreglo con el listado de información que necesitamos traer desde la API de Colleges
  const infoToObtain = ['name',
    'short_description',
    'city',
    'state_abbr',
    'type_category',
    'undergraduate_size',
    'acceptance_rate',
    'four_year_graduation_rate',
    'rankings_best_colleges',
    'sat_average',
    'act_cumulative_midpoint',
    'type_year',
    'avg_cost_of_attendance',
    'website',
  ]

  const showCollege = async (college, infoToObtain = []) => {
    if (!college?.isPrincipal) {
      const collegeComplete = await CompleteUniversityInfo(college, infoToObtain)
        openMyCollegeModal(() => {
          onAddUniversity()
        }, collegeComplete, userId, planId, universities)

    } else {
      openMyCollegeModal(() => {
        onAddUniversity()
      }, college, userId, planId, universities)
    }
  }

  // pagination
  const [page, setPage] = useState(1)
  const [next, setNext] = useState("")
  const [previous, setPrevious] = useState("")
  const [limit, setLimit] = useState(20)

  // data: es la información que traemos de la base de datos, con la query getUniversities
  const { loading, data } = useAllUniversities({
    limit: limit,
    next: next,
    previous: previous,
    search: inputValue.toLowerCase() || '',
  })

  useEffect(() => {

  }, [data, next, limit])

  useEffect(() => {
    setInputValue('')
  }, [isOpen])

  return (
    <>
      <ModalLayout
        isOpen={isOpen}
        toggle={() => toggle()}
        topHead={<span className={`h2 text-title`}>University Directory</span>}
        className="modal-colored modal-sidebar modal-desktop-half white-modal"
        underHead={
          <div
            className="w-100"
            style={{
              borderRadius: 16,
              backgroundColor: "#f0f4f8",
              height: "50px",
            }}
          >
            <Input
              type="addon"
              className="mr-5 px-2 d-flex flex-row-reverse"
              addOnComponent={
                <IconSearch
                  color="#bec5cf"
                  className="d-flex justify-content-start"
                  size={18}
                />
              }
              placeholder="Search"
              onChange={(event) => setInputValue(event.target.value)}
              value={inputValue}
            />
          </div>
        }
      >
        {/* Listado y filtrado de universidades */}
        <TabContent activeTab={loading ? "loading" : "list"}>
          <TabPane tabId={`loading`}>
            <Skeleton count={5} height={40} />
          </TabPane>
          <TabPane tabId={`list`}>
            <div className="h-100 d-flex flex-column">
              {data?.docs?.length > 0 &&
                data?.docs.map(
                  (university, index) =>
                    (!university.deleteAt || university.deleteAt === null) && (
                      <ListItem
                        key={index}
                        item={university}
                        onClick={async () => {
                          showCollege(university, infoToObtain);
                        }}
                        onSelectMultiUniversities={onSelectMultiUniversities}
                        selectedUniversities={selectedUniversities || []}
                      />
                    )
                )}
              <div
                style={{
                  position: 'sticky',
                  bottom: '20px',
                  zIndex: 1000,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                <Button
                  className={`text-center mt-2 w-100 ${textColor}`}
                  style={style}
                  color={colorButton}
                  label={labelButtonUniversities}
                  onClick={handleSetMultiUniversities}
                  disabled={(selectedUniversities?.length <= 0 && universities?.length <= 0) || disableForNoChanges || loadinSetMultiUniversities || selectedUniversities?.length > 10}
                  activity={loadinSetMultiUniversities}
                />
              </div>
              <Pagination
                pageSize={limit}
                changeSize={(size) => {
                  setLimit(size);
                }}
                clickNext={() => {
                  if (page < Math.floor(data.totalDocs / limit)) {
                    setPage(page + 1);
                    setPrevious("");
                    setNext(data.next);
                  }
                }}
                clickPrevious={() => {
                  if (page > 1) {
                    setPage(page - 1);
                    setNext("");
                    setPrevious(data.previous);
                  }
                }}
                hasPrevious={page > 1}
                hasNext={Math.floor(data.totalDocs / limit) > page}
                activePage={page}
                pageCount={
                  Math.floor(data?.totalDocs / limit) > 0
                    ? Math.floor(data?.totalDocs / limit)
                    : 1
                }
                className={`w-100 d-flex flex-row justify-content-between align-items-end pagination-opacity pt-2`}
              />
            </div>
          </TabPane>
        </TabContent>
      </ModalLayout>
    </>
  );
}

UniversityDirectory.defaultProps = {
  universities: collegesMock,
  addUniversity: () => { },
  onAddUniversity: () => { },
  isOpen: false,
  toggle: () => { },
}

UniversityDirectory.propTypes = {
  universities: PropTypes.array,
  addUniversity: PropTypes.func,
  onAddUniversity: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default UniversityDirectory
