import moment from 'moment-timezone'
export const initialState = {
  activities: {
    create: {
      isOpen: false
    },
    planTestDateCreate: {
      isOpen: false
    },
    delete: {
      isOpen: false
    },
    edit: {
      isOpen: false
    },
    disableTest: {
      isOpen: false,
      testId: '',
      onChange: () => {},
    }
  },
  country: {
    create: {
      isOpen: false
    },
    delete: {
      isOpen: false
    },
    edit: {
      isOpen: false
    },
    servicesRate: {
      isOpen: false
    }
  },
  service: {
    create: {
      isOpen: false
    },
    delete: {
      isOpen: false
    },
    edit: {
      isOpen: false
    },
    setTopics: {
      isOpen: false
    }
  },
  topic: {
    create: {
      isOpen: false
    },
    delete: {
      isOpen: false
    },
    edit: {
      isOpen: false
    }
  },
  user: {
    addParentToStudent: {
      isOpen: false
    },
    setEducatorInfo: {
      isOpen: false
    },
    setEducatorUnavailability: {
      isOpen: false
    },
    studentInfo: {
      isOpen: false
    },
    servicesRate: {
      isOpen: false
    },
    servicesRateShort: {
      isOpen: false
    },
    create: {
      isOpen: false
    },
    delete: {
      isOpen: false
    },
    edit: {
      isOpen: false
    },
    editShort: {
      isOpen: false
    },
    invoices: {
      isOpen: false
    },
    notificationHub: {
      isOpen: false
    },
    notificationToggle: {
      isOpen: false
    },
    notificationChange: {
      isOpen: false
    },
    profile: {
      isOpen: false,
      full: false,
      userId: null,
      type: null
    },
    globalRate: {
      isOpen: false,
      type: null,
      userId: '',
      refetch: () => {}
    },
    customService: {
      isOpen: false,
      userId: null,
      type: null
    },
    addCustomService: {
      isOpen: false,
      userId: null,
      type: null
    },
    editGeneralInfo: {
      isOpen: false,
      userId: null,
      type: null
    },
    editBankInfo: {
      isOpen: false,
      userId: null,
      type: null
    },
    familyInfo: {
      isOpen: false,
      userId: null
    },
    availabilityTemporal : {
      dataUser: {}
    },
    availabilityRecurring : {
      dataUser: {}
    },
    addPaymentMethod: {
      isOpen: false,
      userId: null
    },
    educatorAcademicInfo: {
      userData: {},
      universitiesList: [],
    }
  },
  plan: {
    moveStudent: {
      isOpen: false,
      onChange: () => {},
      dataPlan: {},
      useMutation: false
    },
    completeGroupPlan: {
      isOpen: false,
      onChange: () => {},
      dataPlan: {},
      useMutation: false
    },
    deleteGroupPlan: {
      isOpen: false,
      onChange: () => {},
      dataPlan: {},
      useMutation: false
    },
    reviewScheduled: {
      modalInfo: {
        isOpen: false,
        onChange: () => {},
        props: {},
        unselected: [],
        useMutation: false,
      },
      loading: false
    },
    editChildPlan: {
      isOpen: false
    },
    changeTopicEducator: {
      isOpen: false
    },
    selectTopicEducator: {
      isOpen: false
    },
    selectTopicEducatorGroup: {
      isOpen: false
    },
    seeInfo: {
      isOpen: false
    },
    newModal: {
      isOpen: false,
      formProps: {},
      incomplete: false
    },
    addDiscount: {
      isOpen: false
    },
    addComments: {
      isOpen: false
    },
    changePaymentMethod: {
      isOpen: false
    },
    confirmCancelPlan: {
      isOpen: false,
      dataPlan: {},
      confirmAction: () => {}
    },
    viewPlanProgress: {
      isOpen: false,
      dataPlan: {},
      confirmAction: () => {},
      noLessonProgress: false
    },
    editRateLesson: {
      isOpen: false,
      onClickFunction: () => {}
    },
    resumePlanModal: {
      isOpen: false,
      dataPlan: {},
      confirmAction: () => {}
    },
    infoLessonModal: {
      isOpen: false,
      dataLesson: {}
    },
    orientationLessonModal: {
      isOpen: false,
      dataLesson: {}
    },
    resumeOrientationModal: {
      isOpen: false,
      dataLesson: {}
    },
    duplicateLessonModal: {
      isOpen: false,
      dataLesson: {}
    },
    makeUpLesson: {
      isOpen: false,
      dataLesson: {}
    },
    addSustituteEducator: {
      isOpen: false,
      idTopic: '',
      setEducator: () => {},
      topicName: '',
      form: {},
      educator: {},
      idService: ''
    },
    addSustituteEducatorForOrientation: {
      isOpen: false,
      setEducator: () => {},
      form: {},
      educator: {}
    },
    setPendingLesson: {
      isOpen: false,
      lessonId: {}
    },
    cancelLesson: {
      isOpen: false,
      lessonId: '',
      isOrientation: false,
      dataLesson: {},
      refetch: () => {}
    },
    penalizeLesson: {
      isOpen: false,
      dataLesson: {}
    },
    newLessonModal: {
      isOpen: false,
      isEdit: false,
      defaultForm: {}
    },
    editPaymentLesson: {
      isOpen: false,
      dataLesson: {}
    },
    sendAllPlansModal: {
      isOpen: false,
      confirmAction: () => {},
      dataPlan: null
    },
    sendOnePlanModal: {
      isOpen: false,
      confirmAction: () => {},
      dataPlan: null
    },
    editGroupModal: {
      isOpen: false,
      planId: ''
    },
    newGroupModal: {
      isOpen: false,
      testdate: null,
      cuotas: [],
      educatorsGroup: {},
      educatorsIndividual: {},
      lpw: { 0: { partname: 'Part 1' } },
      statusPlan: 'draft',
      payments: {
        registration: true,
        exam: false,
        pro: false,
        lessons: true,
        groupLessons: true,
        discount: {
          registration: 0,
          exam: 0,
          pro: 0,
          lessons: 0
        },
        values: {
          registration: 200,
          exam: 50,
          pro: 50
        }
      }
    },
    setBraincertToLesson: {
      isOpen: false,
      lessonId: null,
      isOrientation: false
    }
  },
  groupPlans: {
    viewSingle: {
      isOpen: false
    },
    selectStudents: {
      isOpen: false,
      selectedStudents: []
    },
    schedulePart: {
      isOpen: false
    },
    schedulePartEdit: {
      isOpen: false
    }
  },
  payments: {
    newPayslip: {
      isOpen: false
    },
    viewPayslip: {
      isOpen: false,
      payslipId: ''
    },
    newPaymentSlip: {
      isOpen: false
    },
    editPaymentSlip: {
      isOpen: false,
      paymentItemId: ''
    },
    editPayslip: {
      isOpen: false,
      payslipId: ''
    },
    processPayslip: {
      isOpen: false,
      isMultiple: false,
      invoiceId: ''
    },
    // ****
    newInvoice: {
      isOpen: false
    },
    viewInvoice: {
      isOpen: false,
      invoiceId: ''
    },
    viewInvoiceWithPymtItem: {
      isOpen: false,
      invoiceId: '',
      paymentItemId: ''
    },
    newPayment: {
      isOpen: false
    },
    editPayment: {
      isOpen: false,
      invoiceId: '',
      kind: ''
    },
    editPaymentPlan: {
      isOpen: false,
      invoiceId: '',
      kind: ''
    },
    editPaymentCustom: {
      isOpen: false,
      invoiceId: '',
      kind: ''
    },
    editPaymentLesson: {
      isOpen: false,
      invoiceId: '',
      kind: ''
    },
    processInvoice: {
      isOpen: false,
      isMultiple: false,
      invoiceId: ''
    },
    newRecord: {
      isOpen: false,
      invoiceId: '',
      amount: 0
    },
    makeRefund: {
      isOpen: false,
      invoice: null,
      issue: null,
      isSimple: true
    },
    transportCostumer: {
      isOpen: false,
      onChange: () => {},
      userId: null
    },
    changePaymentMethod: {
      isOpen: false,
      userId: null,
      paymentMethod: ''
    }
  },
  lesson: {
    rescheduledLesson: {
      isOpen: false,
      dataLesson: {}
    },
    changeTimezoneModal: {
      isOpen: false
    },
    editLessonModal: {
      isOpen: false,
      dataLesson: {},
      editPending: false,
      refetch: () => {}
    },
    wspAlert: {
      isOpen: false,
      dataLesson: {},
      studentsId: [],
      educatorId: null
    },
    rescheduledpending: {
      isOpen: false,
      lessons: []
    },
    cancelMultiple: {
      isOpen: false,
      lessonsId: []
    },
    setPendingMultiple: {
      isOpen: false,
      lessonsId: []
    },
    sendEmailReminder: {
      isOpen: false,
      dataLesson: {},
      studentsId: [],
      educatorId: null
    },
    scheduledConflict: {
      isOpen: false,
      from: moment(),
      ocurrences: [],
      indexOcurrence: 0,
      callBack: () => {}
    },
    confirmDropRescheduled: {
      isOpen: false,
      collissionKinds: [],
      confirmAction: () => {},
      cancelAction: () => {}
    },
    dragAndDropCalendar: {
      isActive: false,
      freeFrames: []
    }
  },
  student: {
    infoStudentModal: {
      isOpen: false,
      dataStudent: {},
      initPage: 'summary',
      full: false,
      profilePage: 'viewProfile'
    },
    createStudentModal: {
      isOpen: false,
    },
    addParentModal: {
      isOpen: false,
      idStudent: null,
      refetch: () => {}
    },
    newCourseMaterials : {
      isOpen: false,
      courseMaterial: {}
    },
    setActivate: {
      isOpen: false,
      dataUser: {}
    }
  },
  parent: {
    infoParentModal: {
      isOpen: false,
      full: false,
      userId: null,
      type: null,
      initPage: 'viewProfile',
      profilePage: 'full-profile',
    },
    createParentModal: {
      isOpen: false,  
      onChange: () => {}  , 
    }
  },
  educator: {
    infoEducatorModal: {
      isOpen: false,
      full: false,
      userId: null,
      type: null,
      initPage: 'summary',
      profilePage: 'full-profile',
    },
    createEducatorModal: {
      isOpen: false,
      onChange: () => {},
    },
  },
  staff: {
    infoStaffModal: {
      isOpen: false,
      dataUser: null,
      onChange: () => {}
    },
  },
  calendar: {
    selectUser: {
      selectedUserData: {},
      upcomming: null
    }
  },
  developer: {
    setIndexPartModal: {
      isOpen: false,
      planId: null,
      lessonData: {}
    },
    setRateToPaymentAndLesson: {
      isOpen: false,
      lessonId: null,
      payments: []
    },
    studentsOnTimezone: {
      isOpen: false,
      timezone: null
    }
  },
  counseling: {
    /**counseling template**/
    listTemplatesModal : {
      isOpen: false,
      templateId: null,
      templateData: null,
      forSelection: false
    },
    selectTemplateModal : {
      isOpen: false,
      onChange: () => {},
      activeTab: 'list',
      setTab: () => {},
    },
    newTaskModal: {
      isOpen: false,
      onChange: () => {},
      isEdit: false,
      taskData: {},
      useMutation: false,
      loading: false,
      completedTask: false
    },
    viewTaskModal: {
      isOpen: false,
      taskData: {},
      useMutation: false
    },
    viewExamModal: {
      isOpen: false,
      examData: {},
      onChange: () => {},
      useMutation: true,
    },
    //DEPRECATED
    submitScoreForm: {
      isOpen: false,
      examData: {},
      onChange: () => {},
      useMutation: true,
      forPlan: false
    },
    //DEPRECATED
    submitScoreFormLoading: {
      loading: false,
    },
    taskOnSubmitScoreModal: {
      taskData: {},
    },
    submitScoreModal: {
      isOpen: false,
      exam: {},
      onChange: () => {},
      showPlansToAdd: false,
      planId: '',
    },
    submitScoreModalLoading: {
      loading: false,
    },

    deleteTaskModal: {
      isOpen: false,
      onChange: () => {},
      taskData: {},
      useMutation: false,
      loading: false
    },
    newStepModal: {
      isOpen: false,
      onChange: () => {},
      defaultValue: null,
      currentPhase: null
    },
    deleteTemplateModal: {
      isOpen: false,
      onChange: () => {}
    },
    /**counseling plans**/
    newPlanModal: {
      isOpen: false,
      onChange: () => {},
      isEdit: false,
      dataPlan: {}
    },
    viewPlanModal: {
      isOpen: false,
      onChange: () => {},
      dataPlan: {}
    },
    addDiscountModal: {
      isOpen: false,
      onChange: () => {}
    },
    editPaymentModal: {
      isOpen: false,
      onChange: () => {},
      totalAmount: Number.POSITIVE_INFINITY,
      defaultValue: {amount: null, date: null}
    },
    addCounselorModal: {
      isOpen: false,
    },
    /**Confirm modal for close action **/
    confirmCloseModal: {
      isOpen: false,
      onChange: () => {},
    },

    sendOnePlanModal: {
      isOpen: false,
      onChange: () => {},
      dataPlan: {},
      loading: false,
      useMutation: false
    },
    assingTaskModal: {
      isOpen: false,
      onChange: () => {},
      taskData: {},
      useMutation: false,
      loading: false
    },
    completeTaskModal: {
      isOpen: false,
      onChange: () => {},
      taskData: {},
      useMutation: false,
      loading: false
    },
    cancelPlanModal: {
      isOpen: false,
      onChange: () => {},
      planData: {},
      loading: false,
      useMutation: false
    },
    counselingPlanList: {
      refetch: () => {}
    },
    //universities
    univeristyDirectory: {
      isOpen: false,
      onChange: () => {},
      refetch: () => {},
    },
    collegeModal: {
      isOpen: false, 
      onChange: () => {}, 
      college: {},
      userId: null
    },
    confirmAppendCollege: {
      isOpen: false, 
      onChange: () => {}
    },
    createTimelineModal: {
      isOpen: false,
      onChange: () => {},
      userData: null,
      timelineData: null,
      useMutation: false,
      timelineId: null
    },
    deleteTimelineModal: {
      isOpen: false,
      onChange: () => {},
      timelineId: null,
      useMutation: false
    },
    createEventModal: {
      isOpen: false,
      onChange: () => {},
      event: null
    },
    createTestModal: {
      isOpen: false,
      onChange: () => {},
      test: null
    },
    createPlanModal: {
      isOpen: false,
      onChange: () => {},
      plan: null
    },
    deleteEventModal: {
      isOpen: false,
      onChange: () => {},
      eventId: null
    },
    sendTaskEmailReminder: {
      isOpen: false,
      onChange: () => {},
      taskData: {planId: null, id: null}
    },
    loginInfoModal : {
      isOpen: false,
      onChange: () => {},
    },
    showScoresModal : {
      isOpen: false,
      onChange: () => {},
    },
    allStudentWithoutTask: {
      isOpen: false,
      onChange: () => {},
    },
    timelinePlanModal: {
      isOpen: false,
      onChange: () => {},
    },
    scheduleMeetingModal : {
      isOpen: false,
      onChange: () => {},
      task: {},
    },
    uploadFileModal : {
      isOpen: false,
      onChange: () => {},
      task: {},
    },
    setApplicationStatus: {
      isOpen: false,
      onChange: () => {},
      dataPlan: null,
      useMutation: false
    },
    setCompletedPlanStatus: {
      isOpen: false,
      onChange: () => {},
      dataPlan: null,
      useMutation: false
    },
    setStatusConfirm: {
      isOpen: false,
      onChange: () => {},
      dataPlan: null,
      useMutation: false,
      newStatus: null
    },
    completePlanModal: {
      isOpen: false,
      onChange: () => {},
      useMutation: false,
      loading: false,
      dataPlan: null
    },
    assignMultipleTasks: {
      isOpen: false,
      onChange: () => {},
      selectedTasks: []
    }
  },
  pdfEditor: {
    selectPdfTemplate: {
      isOpen: false
    },
    createPdfTemplate: {
      isOpen: false, 
      isEdit: false,
      template: null,
      onChange: () => {},
      useMutation: false
    }
  },
  scheduledMeeting: {
    isOpen: false,
    onChange: () => {},
  },
  emailsList : {
    isOpen: false,
    onChange: () => {},
    query: {limit: 0,
      next: '',
      previous: '',
      role: 'STUDENT',
      search: null,
      serviceIds: [],
      topicIds: [],
      testDate: null,
      classYear: [],
      countriesIds: [],
      statuses: true,
    },
    type: '',
  },
  downloadExcel: {
    isOpen: false,
    onChange: () => {},
    query: {limit: 0,
      next: '',
      previous: '',
      role: 'STUDENT',
      search: null,
      serviceIds: [],
      topicIds: [],
      testDate: null,
      classYear: [],
      countriesIds: [],
      statuses: true,
    },
    type: '',
  },
  notes: {
    isOpen: false,
    dataNote: {
      id: '',
      text: ''
    },
    onChange: () => {},
  },
  dashboard: {
    dismissTimelinePLan: {
      isOpen: false,
      onChange: () => {},
      plan: {}
    },
    checkInStudent: {
      isOpen: false,
      onChange: () => {},
      student: {},
      reason: 'homework'
    },
    postponeReminderModal: {
      isOpen: false,
      onChange: () => {},
      plan: {}
    },
    testDateCheckInStudent: {
      isOpen: false,
      onChange: () => {},
      student: {},
      reason: 'getScore'
    },
    dismissTestDateNotification: {
      isOpen: false,
      onChange: () => {},
      planIds: [],
      type: null
    },
    issueDismissNotification: {
      isOpen: false,
      onChange: () => {},
      student: {},
      reason: 'homework'
    }
  },
  analitics: {
    sendWeeklyInsight: {
      isOpen: false,
      onChange: () => {},
      useMutation: false
    },
    sendInvoiceSummary: {
      isOpen: false,
      onChange: () => {},
      useMutation: false
    }
  },
  completeAllPlans: {
    isOpen: false,
  }
}
