import React from 'react'
import { IconArrowRight } from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'

const ListItem = (props) => {
  const {item, onClick, onSelectMultiUniversities = () => {}, selectedUniversities } = props
  return (
    <>
      <div className="d-flex flex-row align-items-center list-item-university py-2 px-2">
        <input
          type="checkbox"
          checked={selectedUniversities.some((uni) => uni.id === item.id)}
          onChange={(e) => onSelectMultiUniversities(e, item)}
          className="mr-2 w-10 h-10 cursor-pointer"
        />
        <div className="w-25 align-self-center">
          <img
            src={item?.logo}
            alt={`${item?.name}`}
            style={{ width: "50px", height: "50px" }}
          />
        </div>
        <div className="w-100 align-self-center">{item?.name}</div>
        <div className={`align-self-center`}>
          <IconArrowRight color="#7e8c9e" size={16} onClick={onClick} />
        </div>

      </div>

    </>
  );
}

ListItem.defaultProps = {
  item: {},
  onClick: () => {},
  onSelect: () => {}
}

ListItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  onSelect: PropTypes.func
}

export default ListItem
