import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  IconUser,
  ModalLayout,
  IconCountry,
  IconArrowLeft,
  IconBank,
  Button,
  IconSignPlus,
  DropDownNew,
  DropDownItem,
  Activity,
  EarlyActionBadge,
  EarlyDecisionBadge,
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import './collegeModal.css'
import useMyCollegeModal from './useMyCollegeModal'
import SummaryTab from './Summary'
import TasksTab from './Tasks'
import DocumentsTab from './Documents'
import InfoTab from './Info'
import ShowUniversityComponent from './Components/ShowUniversity'
import useSetMyUniversity from '../Model/useSetMyUniversity'
import useSetUniversityStatus from '../Model/useSetUniversityStatus'
import { SET_DATA_MODAL_FORM_LIST } from '../../../store/modal/const'
import useTranslation from '../../../i18n/useTranslation'
import ViewUniversityWebsite from '../../Constants/Universities/Modals/Components/ViewUniversityWebsite'
import useUniveristyDirectory from '../UniversityDirectory/useUniversityDirectory'
import { gql, useLazyQuery } from '@apollo/client'
import useConfirmAddCollege from './ConfirmAddCollegeCharge/useConfirmAddCollege'
import ConfirmAddCollegeCharge from './ConfirmAddCollegeCharge'
import {UniversitiesStatus} from '../Constants/universitiesStatus'


const UNIVERSITY_LIMIT = 10

const CollegeModal = (props) => {

  const {
    isOpen,
    onChange,
    // open,
    // close,
    toggle,
    college,
    planId,
    // universities,
    setUpdateUniversity,
    userId
  } = useMyCollegeModal()
  const {open: openConfirmCollege, close: closeConfirmCollege} = useConfirmAddCollege()
  const {
    refetch
  } = useUniveristyDirectory()
  const dispatch = useDispatch()
  const { translate } = useTranslation()

  const reduxData = useSelector(
    (state) => state?.modal?.counseling.collegeModal,
  )

  const realCollegesList = useSelector(
    (state) => state?.modal?.counseling.collegeModal?.universities,
  )
  // const userId = useSelector( (state) => state?.modal?.counseling?.collegeModal?.userId)

  const collegeData = useSelector(
    (state) => state?.modal?.counseling.collegeModal?.college,
  )
  const [activeTab, setActiveTab] = useState('summary')

  const noInfo = 'No info'
  // Estado para saber si es "My University" o "University" y renderizar de acuerdo a ello
  const [isMyCollege, setIsMyCollege] = useState(false)


  const updateCollege = (college) => {
    let filterCollege = realCollegesList?.filter(x => x.id === college?.id)
      if (filterCollege !== []) {
        setUpdateUniversity({...collegeData, early: filterCollege[0]?.early || 'nothing', status: filterCollege[0]?.status || 'wishlist'})
      }
      setIsMyCollege(filterCollege.length > 0)
    }
  // eslint-disable-next-line
  const {submit: addUniversity, loading, data} = useSetMyUniversity((resp) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.collegeModal',
      data: {
        ...reduxData,
        universities: resp?.data?.setMyUniversity?.studentInfo?.universities}
    })
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.univeristyDirectory',
      data: {
        universities: resp?.data?.setStatusUniversity?.studentInfo?.universities
      }
    })
    onChange()
    refetch()
  })

  const {submit: setStatus, loading: loadingStatus} = useSetUniversityStatus((resp) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.collegeModal',
      data: {
        ...reduxData,
        universities: resp?.data?.setStatusUniversity?.studentInfo?.universities
      }
    })
    onChange()
  })

  useEffect(() => {
    if (!isOpen) return
    college?.id && updateCollege(college)
    setIsMyCollege(realCollegesList?.filter(
      (x) => x?.id === college?.id,
    ).length > 0)
     // eslint-disable-next-line
  }, [planId])

  useEffect(() => {
    if(realCollegesList) {
      let collegeFilter = realCollegesList?.filter( (x) => x?.collegeId === collegeData?.collegeUnitId,)
      if (collegeFilter) updateCollege(collegeFilter[0])
    } 
     // eslint-disable-next-line
    }, [realCollegesList])
    
  useEffect(() => {
    if (data) {
      let collegeFilter = data?.studentInfo?.universities?.filter( (x) => x?.id === collegeData?.id)
      setIsMyCollege(collegeFilter?.length > 0)
    }
     // eslint-disable-next-line
  }, [data])
  const changeStatus = item => {
    if (!collegeData?.id && !userId) return
    setStatus({
      universityId: collegeData?.id,
      studentId: userId,
      status: item
    })
  }



  // se usa para verificar si el usuario 
  //ha alcanzado el límite de universidades
  const [getUserAndPlan] = useLazyQuery(
    gql`
      query ($id: String!, $planId: ID!) {
        user (id: $id) {
          studentInfo {
            universities {
              id
            }
          }
        }
        plan (planId: $planId) {
          id
          status
        }
      }
    `
  )

  const [overLimit, setOverLimit] = useState(false)
  useEffect(() => {
    if(!userId || !planId) setOverLimit(false)
    else {
      getUserAndPlan({
        variables: {id: userId, planId}
      }).then((res) => {
        const user = res?.data?.user
        const plan = res?.data?.plan
        setOverLimit(
          ['application', 'postApplication'].includes(plan?.status) &&
          user?.studentInfo?.universities?.length >= UNIVERSITY_LIMIT
        )
      })
    }
  }, [userId, getUserAndPlan, college, planId])


  return (
    <>
      <ModalLayout
        // modalScrollClassName={'h-100 m-0 p-0 px-1'}
        className='modal-colored modal-sidebar modal-desktop-half college-modal'
        isOpen={isOpen}
        toggle={() => {
          setActiveTab('summary')
          toggle()
        }}
        topHead={
          isMyCollege ? (
            <div className='d-flex flex-row'>
              <img
                src={
                  collegeData?.logo
                }
                alt={`${collegeData?.name}`}
                style={{ width: '100px' }}
                className='ml-4'
              />
              {
                loadingStatus
                  ?
                    <Activity />
                  :
                    <DropDownNew
                      className={`ml-3`}
                      options={
                        UniversitiesStatus.map(
                          e => {
                            return {
                              component: 
                                <DropDownItem>
                                  <div className='d-flex flex-row align-items-center' onClick={() => {changeStatus(e)}}>
                                    <div className={`circle bg-${e}`}/>
                                    <div className='ml-2'>{translate(`universities.${e}`)}</div>
                                  </div>
                                </DropDownItem>
                            }
                          }
                        )
                      }
                      direction='bottomLeft'
                    >
                      <div className={`circle bg-${collegeData?.status}`}/>
                    </DropDownNew>
              }
            </div>
          ) : (
            <div
              className='d-flex flex-row align-items-start btn-link'
              onClick={toggle}
            >
              <div>
                <IconArrowLeft size={15} color='#8c90ff' />
              </div>
              <div>
                <span
                  className='ml-1'
                  style={{
                    color: '#8c90ff',
                  }}
                >Back</span>
              </div>
            </div>
          )
        }
        underHead={
          isMyCollege ? (
            // Si es My University muestra esto:
            <div className='mb-2 align-items-center'>
              <div className='mt-4 p-0 d-flex flex-row'>
                <div className='h3 ml-4 align-middle' style={{ color: '#2C3F58' }}>{collegeData?.name}</div>
                {/* Renderiza solo si está marcado como Early Action */}
                {collegeData?.early === 'action' ? (
                  <EarlyActionBadge />
                ) : // Renderiza solo si está marcado como EarlyDecision
                ['decision', 'decision2'].includes(collegeData?.early) ? (
                    <EarlyDecisionBadge edVersion={collegeData?.early}/>
                  ) : null}
              </div>
              <div className='mt-2 h-100 d-flex flex-row'>
                <div className='d-flex flex-row ml-4'>
                  <span className='mr-5 h45 text-secondary d-flex flex-row align-items-center'>
                    <IconCountry className='text-secondary mr-2' size={14} />{' '}
                    {collegeData?.city}, {collegeData?.stateAbbr}
                  </span>
                </div>
                <span className='mr-5 h45 text-secondary d-flex flex-row align-items-center'>
                  <IconUser className='text-secondary mr-1' size={14} /> ~
                  {(collegeData?.undergraduateSize / 1000).toFixed(1)}K{' '}
                  undergraduates
                </span>
              </div>
              <ViewUniversityWebsite styles={'ml-4'} college={college}/>
            </div>
          ) : (
            // Si es una University muestra esto:
            <div className='d-flex flex-column mt-3' style={{ color: 'black' }}>
              <div>
                <img src={collegeData?.logo} alt='' style={{ width: '24%' }} />
              </div>
              <div className='mt-4 h3'>{collegeData?.name}</div>
              <div className='general-university-information'>
                <div>
                  <span className='h45 university-position mb-3'>
                    {collegeData?.rankingsBestColleges
                      ? '#' +
                      collegeData.rankingsBestColleges?.value +
                      ' Best Colleges in America'
                      : noInfo}
                  </span>
                </div>

                <div className='primary-information mt-4 mb-2'>
                  <div className='d-flex flex-row'>
                    <div className='h45 text-secondary d-flex flex-row align-items-center'>
                      <IconBank className='text-secondary mr-2' size={14} />{' '}
                      {collegeData?.typeCategory}
                    </div>
                    <span className='h45 text-secondary d-flex flex-row align-items-center ml-5'>
                      <IconCountry className='text-secondary mr-2' size={14} />{' '}
                      {collegeData?.city}, {collegeData?.stateAbbr}
                    </span>
                  </div>
                  <div className='d-flex flex-row mt-2'>
                    <span className='h45 text-secondary d-flex flex-row align-items-center'>
                      <IconUser className='text-secondary mr-2' size={14} /> ~
                      {(collegeData?.undergraduateSize / 1000).toFixed(1)}K{' '}
                      undergraduates
                    </span>
                  </div>
                  <ViewUniversityWebsite styles={''} college={college}/>
                </div>
                <span className='h45 line-height-base' style={{ color: '#49596F' }}>
                  {collegeData?.shortDescription
                    ? collegeData?.shortDescription
                    : "There's no description"}
                </span>
              </div>
            </div>
          )
        }
        disabledScrollAnimation={false}
      >

        {isMyCollege ? (
          <Fragment>
            <div>
              <Nav className='znk-scroll-horizontal justify-content-between flex-nowrap mt-3'>
                <NavItem
                  className='nav-title mr-2 h4'
                  active={activeTab === 'summary'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('summary')
                    }}
                  >
                    Summary
                  </NavLink>
                </NavItem>
                <NavItem
                  className='nav-title mr-2 h4'
                  active={activeTab === 'tasks'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('tasks')
                    }}
                  >
                    Tasks
                  </NavLink>
                </NavItem>
                <NavItem
                  className='nav-title mr-2 h4'
                  active={activeTab === 'documents'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('documents')
                    }}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem
                  className='nav-title mr-2 h4'
                  active={activeTab === 'info'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('info')
                    }}
                  >
                    Info
                  </NavLink>
                </NavItem>
              </Nav>
              <div className='d-flex flex-row justify-content-between mt-4'>
                <TabContent activeTab={activeTab} className='px-0 w-100'>
                  <TabPane tabId='summary'>
                    <SummaryTab 
                      data={null} loading={null} college={collegeData} setIsMyCollege={setIsMyCollege}
                      onChange={(e) => {
                        onChange(e)}} />
                  </TabPane>
                  <TabPane tabId='tasks'>
                    <TasksTab college={collegeData} planId={planId} />
                  </TabPane>
                  <TabPane tabId='documents'>
                    <DocumentsTab college={collegeData} />
                  </TabPane>
                  <TabPane tabId='info'>
                    <InfoTab universityData={collegeData} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </Fragment>
        ) : (
          <>
            <ShowUniversityComponent university={collegeData} />
            <Button
              style={{
                position: 'sticky',
                bottom: '20px',
              }}
              color='primary'
              className='position-sticky w-100 mb-4'
              activity={loading}
              onClick={() => {
                const addCollege = (charge = false, planId = null) => {
                  addUniversity({universityId: collegeData?.id, userId, charge, planId})
                }
                if(overLimit) {
                  openConfirmCollege(
                    (confirm) => {
                      if(confirm) {
                        closeConfirmCollege()
                        addCollege(true, planId)
                      }
                  })
                } else addCollege()
              }
              }
              label={
                <div className='d-flex align-items-center znk-check'>
                  <span>
                    Add {collegeData?.name}{' '}to my list
                  </span>
                  <IconSignPlus
                    className='text-light add-icon-university'
                    color='#696CFF'
                    style={{ transform: 'rotate(45deg)' }}
                  />
                </div>
              }
            />
          </>
        )

        }
      </ModalLayout>
      <ConfirmAddCollegeCharge/>
    </>
  )
}

export default CollegeModal
