import moment from 'moment'

const PaymentTypes = {
  registration: 'registration',
  exam: 'exam',
  pro: 'pro',
  // atSigning: 'atSigning',
}
export const marathonPrice = 1365
export const OtherFixed = [
  { key: PaymentTypes.registration, value: 200 },
  { key: PaymentTypes.exam, value: 50 },
  { key: PaymentTypes.pro, value: marathonPrice },
  // { key: PaymentTypes.atSigning, value: 0 }
]
export const generatePaymentObject = () => {
  return {
    lessons: false,
    ...OtherFixed.reduce(
      (values, {key}) => {
        values[key] = false
        return values
      },
      {
        // registration: false,
        // exam: false,
        // atSigning: false,
        // pro: false,
      }
    ),
    discount: OtherFixed.reduce(
      (discount, {key}) => {
        discount[key] = 0
        return discount
      },
      {
        // registration: 0,
        // exam: 0,
        // pro: 0,
        // atSigning: 0,
        lessons: 0 
      }
    ),
    values: OtherFixed.reduce(
      (values, {key, value}) => {
        values[key] = value
        return values
      },
      {
        // registration: 200,
        // exam: 50,
        // pro: 50,
        // atSigning: 100 
      }
    )
  }
}
export const parsePlanToEdit = (plan, serviceName) => {
  const namesPayments = {
    [`Payment ${PaymentTypes.pro}`]: { name: PaymentTypes.pro },
    [`Payment ${PaymentTypes.exam}`]: { name: PaymentTypes.exam },
    [`Payment ${PaymentTypes.registration}`]: { name: PaymentTypes.registration },
    [`Payment ${PaymentTypes.atSigning}`]: { name: PaymentTypes.atSigning }
  }
  // objeto payments original sin ningún campo checkeado
  let payments = generatePaymentObject()
  // {
  //   registration: false,
  //   exam: false,
  //   pro: false,
  //   lessons: false,
  //   discount: {
  //     registration: 0,
  //     exam: 0,
  //     pro: 0,
  //     lessons: 0
  //   },
  //   values: {
  //     registration: 200,
  //     exam: 50,
  //     pro: 50
  //   }
  // }
  let paymentStart
  (plan?.paymentItemsConfig || []).forEach((pay) => {
    if (pay.type === 'others-fixed') {
      if(namesPayments[pay.description]) {
        namesPayments[pay.description].used = true
        payments = {
          ...payments,
          [namesPayments[pay.description].name]: true,
          discount: {
            ...payments.discount,
            [namesPayments[pay.description].name]: pay.percentDiscount
          },
          values: {
            ...payments.values,
            [namesPayments[pay.description].name]: pay.amountWithoutDiscount
          }
        }
      }
    } else {
      if (!paymentStart || paymentStart > moment(pay.date)) paymentStart = moment(pay.date).endOf('month')
      payments = {
        ...payments,
        lessons: true,
        discount: {
          ...payments.discount,
          lessons: pay.percentDiscount
        }
      }
    }
  }, {})

  //para timelines plans
  if(plan.isTimelinePlan) payments.lessons = true

  if (!paymentStart) paymentStart = moment().endOf('month')
  return plan.kind !== 'group' ? {
    planId: plan.id,
    groupId: plan.groupId,
    planname: plan.name,
    amount: plan.pricingRate,
    paymentstart: paymentStart.format('YYYY-MM-DD'),
    testdate: {
      name: `${serviceName} ${moment(plan.testDate).format('MMM Do, YYYY')}`,
      date: plan.testDate
    },
    typePlan: plan.kind === 'lessonsPerWeek' ? 'date' : 'time',
    educators: (plan?.recommendedEducators || []).reduce((allEdu, edu) => { 
      return { 
        ...allEdu, 
        [edu.topicId]: plan.recommendedEducators
          .filter(({topicId}) => edu.topicId === topicId)
          .map((educator) => {
            return { ...educator, id: educator.educatorUserId }
          }) 
      } 
    }
      , {}),
    idservice: plan.serviceId,
    statusPlan: plan.status,
    student: {
      ...plan?.studentUser,
      id: plan?.studentUser?.studentUserId
    },
    /**
      { id: 'totalLesson', text: 'Total' },
      { id: 'lessonPerWeek', text: translate('modal.newplan.scheduledetails.perweek') }
     * **/
    typeLesson: plan?.fixedParts ? plan.fixedParts[0].type : null,
    lpw: (plan?.parts || plan?.fixedParts || []).reduce((allParts, part, index) => {
      return {
        ...allParts,
        [index]: {
          ...(part[['lessonsPerWeek'].includes(plan.kind) ? 'lessonPerWeekPerTopic' : 'TotalPerTopic'] || []).reduce((allLpw, lpw) => {
            return {
              ...allLpw,
              [lpw.topicId]: { value: lpw.lessonsPerWeek, lock: lpw.lockLessons }
            }
          }, {}),
          startdate: part.from,
          enddate: part.to,
          partname: part.name
        }
      }
    }, {}),
    isFirstPlan: plan.isFirstPlan,
    typePlanPricing: plan.typePlanPricing,
    payments: payments,
    paymentItemsConfig: plan?.paymentItemsConfig ? plan.paymentItemsConfig.filter((obj) => obj.type === 'lessons-payment') : [],
    locationPlan: plan?.location?.type,
    locationPlanLink: plan?.location?.linkEducator || null,
    checkInDates: plan?.checkInDates || [],
    isAtomic: plan?.isAtomic || false,
    comments: plan?.comments || ''
  }
    : {
      planId: plan.id,
      groupId: plan.groupId,
      planname: plan.name,
      amount: plan.pricingRate,
      testdate: {
        name: `${serviceName} ${moment(plan.testDate).format('MMM Do, YYYY')}`,
        date: plan.testDate
      }
    }
}
export const redirectToSendAllPlan = (planData, refetch, dispatch) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'plan.sendAllPlansModal',
    data: {
      isOpen: true,
      dataPlan: planData,
      refetch: refetch
    }
  })
}
export const redirectToSendOnePlan = (planData, refetch, dispatch) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'plan.sendOnePlanModal',
    data: {
      isOpen: true,
      dataPlan: planData,
      refetch: refetch
    }
  })
}
export const openNewPlanModal = (form, dispatch) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'plan.editGroupModal',
    data: { isOpen: true, planId: form.id, isDuplicate: form?.isDuplicate || false }
  })
}
export const openEditChildPlan = (info, dispatch) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'plan.editChildPlan',
    data: { isOpen: true, info }
  })
}
export const openNewPlanIndividualModal = (form, dispatch, onChange = () => {}) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'plan.newModal',
    data: { isOpen: true, formProps: form, incomplete: false, onChange }
  })
}
export const redirectToCancelOrPause = (newStatus, dataPlan, dispatch, refetch) => {
  if (dataPlan.status === 'sent' || dataPlan.status === 'draft') {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.confirmCancelPlan',
      data: {
        isOpen: true,
        dataPlan: {
          planId: dataPlan.id,
          paymentData: {
            typePlanPricing: dataPlan?.typePlanPricing,
            pricingRate: dataPlan?.pricingRate,
            groupPricingRate: dataPlan?.groupPricingRate
          },
          newStatus
        },
        refetch: refetch
      }
    })
  } else {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.viewPlanProgress',
      data: {
        isOpen: true,
        dataPlan: {
          kind: dataPlan.kind,
          planId: dataPlan.id,
          paymentData: {
            typePlanPricing: dataPlan?.typePlanPricing,
            pricingRate: dataPlan?.pricingRate,
            groupPricingRate: dataPlan?.groupPricingRate
          },
          newStatus
        },
        refetch: () => {refetch()}
      }
    })
  }
}
export const redirectToResumePlan = (planData, refetch, dispatch) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'plan.resumePlanModal',
    data: {
      isOpen: true,
      dataPlan: planData,
      refetch: refetch
    }
  })
}
export const openPlanModal = (planInfo, extraConfig, dispatch) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'plan.seeInfo',
    data: { isOpen: true, info: planInfo, ...extraConfig }
  })
}
export const viewGroupPlan = (planInfo, extraConfig, dispatch) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'groupPlans.viewSingle',
    data: { isOpen: true, info: planInfo.id }
  })
}

export default {
  parsePlanToEdit,
  redirectToSendAllPlan,
  redirectToSendOnePlan,
  openNewPlanModal,
  openNewPlanIndividualModal,
  redirectToCancelOrPause,
  redirectToResumePlan,
  openPlanModal,
  viewGroupPlan,
  generatePaymentObject,
  OtherFixed
}
