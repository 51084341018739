import React, { useState, useEffect, useMemo } from 'react'
import {
  Modal,
  StickyButton,
  ButtonOptions,
  Form,
  IconClose,
  DropDownItem,
  DropDownSub
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import Services from '../../../../Model/Services/List'
import CreateLessonPerWeekPlan from '../../../../Model/Plan/CreateLessonPerWeekPlan'
import CreateLessonsFixedPlan from '../../../../Model/Plan/CreateLessonsFixedPlan'
import EditLessonsPerWeekPlan from '../../../../Model/Plan/EditLessonsPerWeekPlan'
import EditFixedPlansService from '../../../../Model/Plan/EditFixedPlansService'
import PaymentSchedule from './Components/PaymentSchedule'
import PlanStudent from './Components/PlanStudent'
import PlanDetails from './Components/PlanDetails'
import ScheduleDetails from './Components/ScheduleDetails'
import EducatorsPlan from './Components/EducatorsPlan'
import PricingPlan from './Components/PricingPlan'
import CheckIns from './Components/CheckIns'
import moment from 'moment'
import useTranslation from '../../../../i18n/useTranslation'
import {showAlert} from '../../../../common/Alert/util'
import {totalLesson} from './util.js'
import useBubblesModalHeader from '../../../../bubbles/useBubblesModalHeader'
import { TYPEFIXED } from '../../const' 
import ProgramSentPlan from '../SendOnePlan/Components/ProgramSentPlan'
import { OtherFixed, generatePaymentObject } from '../../Fields/utils'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import AddComments from './Components/AddComments.js'

const PlanStatus = {
  draft: 'draft',
  sent: 'sent',
}

const NewPlan = (props) => {
  const { refetch, educators } = props
  const styleBubbles = useBubblesModalHeader('scheduled')
  const dispatch = useDispatch()
  const { isOpen, formProps, incomplete, onChange = () => {} } = useSelector((state) => state.modal.plan.newModal)
  const { translate } = useTranslation()
  const isEdit = formProps && JSON.stringify(formProps) !== JSON.stringify({})
  //el plan se está duplicando, al final del proceso crea un nuevo plan
  const isDuplicate = useMemo(() => formProps?.isDuplicate || false, [formProps])
  //el plan se está creando desde un timeline
  const isTimelinePlan = useMemo(() => formProps?.isTimelinePlan || false, [formProps])
  const {timelinePlanId, timelineId} = useMemo(() => {return {timelinePlanId: formProps?.timelinePlanId, timelineId: formProps?.timelineId}}, [formProps])
  const openAddComments = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.addComments',
      data: { isOpen: true }
    })
  }
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.newModal',
      data: { isOpen: !isOpen, formProps: formProps }
    })
  }
  const { data } = Services({ limit: 0 })
  const [form, setForm] = useState(
    isEdit
      ? formProps
      : {
        cuotas: [],
        planId: '',
        lpw: {
          0: {
            partname: 'Part 1'
          }
        },
        statusPlan: 'draft',
        payments: generatePaymentObject()
      })


  const currentService = data ? data.docs && data.docs.find((serv) => serv.id === form.idservice) : null
  const [cuotas, setCuotas] = useState(
    isEdit && formProps?.paymentItemsConfig?.length > 0
      ? formProps.paymentItemsConfig.map((cuo) => {
        return {
          value: cuo.amountWithoutDiscount,
          time: cuo.date,
          custom: false
        }
      })
      : []
  )
  const [isAtomic, setIsAtomic] = useState(false)
  useEffect(() => {
    if (formProps?.isAtomic) {
      setIsAtomic(formProps.isAtomic)
    } else {
      setIsAtomic(false)
    }
  }, [formProps])
  const [canReset, setCanReset] = useState(false)
  const [canDistributed, setCanDistributed] = useState(false)
  const TotalLessonPart = (lpwInd) => {
    let count = 0
    if (form.lpw) {
      if (form.typePlan === 'time' && form.typeLesson === 'totalLesson') {
        Object.keys(form.lpw[lpwInd]).filter(
          (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
        ).forEach((idTopic) => {
          count += form.lpw[lpwInd][idTopic].value
        })
        return count
      } else {
        let weeks = 1
        if (form.lpw[lpwInd].startdate && form.lpw[lpwInd].enddate) {
          const partFrom = moment(form.lpw[lpwInd].startdate)
          const partTo = moment(form.lpw[lpwInd].enddate)
          // si una semana tiene menos de tres dias no contarla como una semana valida
          weeks = (partFrom.diff(partTo, 'week') * -1) + 1
          if (partTo.day() < partFrom.day() && partTo.day() !== 0 && weeks >= 2) {
            // en este caso diff cuenta una semana menos
            weeks += 1
          }
          if (partFrom.day() > 3 && partFrom.day() !== 0 && weeks >= 2) {
            weeks -= 1
          }
          if (partTo.day() < 3 && partTo.day() !== 0 && weeks >= 2) {
            weeks -= 1
          }
        }
        Object.keys(form.lpw[lpwInd]).filter(
          (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
        ).forEach((idTopic) => {
          count += form.lpw[lpwInd][idTopic].value * weeks
        })
        return count
      }
    }
    return count
  }
  const TotalLesson = () => {
    return totalLesson(form)
  }
  const mapCuotas = (cuo) => {
    const totalL = TotalLesson()
    const start = form.paymentstart ? moment.utc(form.paymentstart).endOf('month').add(-2, 'd') : null
    //descontar monto por atSigning
    const totalValue = 
      (form.typePlanPricing === 'permonth' ? form.amount : (totalL * form.amount)) - 
      (form?.payments.values.atSigning || 0)
    return cuo.map((pay) => {
      const aux = totalValue / cuo.length
      if (start) {
        const time = start.clone()
        start.add(1, 'month')
        return { ...pay, value: aux, time: time.toISOString() }
      }
      return { ...pay, value: aux }
    })
  }
  const GetFeeFromRangeDate = (from, to) => {
    // Caso 1: Si tiene más lecciones en el primer mes que la cantidad de lecciones por semana,
    // entonces se debe aplicar un pago por ese mes (COMIENZA AL MENOS UNA SEMANA ANTES DEL PRIMER MES)
    // Caso 2: Si tiene más lecciones en el último mes que el doble de lecciones por semana,
    // entonces se debe aplicar un pago por ese mes (si NO ACABA ANTES DE LA SEGUNDA SEMANA DEL SIGUIENTE MES)
    // Caso 3: Si la parte es tan corta como para NO COMENZAR AL MENOS UNA SEMANA ANTES DEL PRIMER MES y
    // ademas ACABA ANTES DE LA SEGUNDA SEMANA DEL SIGUIENTE MES se debe aplicar un solo pago al final
    // del segundo mes
    const BeginAtLeastOneWeekBeforeFirtsMonth = moment(from).endOf('month').diff(moment(from), 'days') >= 7
    const FinishBeforeSecondWeekOfFinalMonth = moment(to).diff(moment(to).startOf('month'), 'days') < 14
    from = moment(from).startOf('month')
    to = moment(to).endOf('month')
    const payments = []
    let c = 0
    while (moment(from) < moment(to)) {
      if (c === 0) {
        // primer payment
        if (BeginAtLeastOneWeekBeforeFirtsMonth) {
          // aplicar payment al final del mes FROM
          // caso 1
          payments.push({
            time: moment(from),
            value: form.amount ? form.amount : 0,
            custom: false
          })
        }
      } else if (
        parseInt(moment(from).clone().format('M')) === parseInt(moment(to).format('M'))
      ) {
        // ultimo payment
        if (!FinishBeforeSecondWeekOfFinalMonth || payments.length === 0) {
          // aplicar payment al final del mes TO
          // caso 2
          payments.push({
            time: moment(from),
            value: form.amount ? form.amount : 0,
            custom: false
          })
        }
      } else {
        payments.push({
          time: moment(from),
          value: form.amount ? form.amount : 0,
          custom: false
        })
      }
      from = moment(from).add(1, 'M')
      c++
    }
    setCuotas(mapCuotas(payments))
  }
  const GetTotalPay = () => {
    let total = 0 // valor total de cuotas
    cuotas.forEach((cuo) => {
      total += cuo.value
    })
    return total
  }
  const InitPayment = () => {
    // crea los payments iniciales
    // form.lpw['0'] es el indice de la primera parte
    // parts - 1 es el indice de la ultima parte por lo tanto debería existir form.lpw[part-1]
    // obtener la fecha de inicio de la primera parte y la fecha de fin de la última parte
    const parts = Object.keys(form.lpw).length
    let start = form.lpw['0'] ? form.lpw['0'].startdate ? form.lpw['0'].startdate : null : null
    let end = form.lpw[parts - 1] ? form.lpw[parts - 1].enddate ? form.lpw[parts - 1].enddate : null : null
    if (form.typeLesson === 'totalLesson' && form.typePlan === 'time') {
      GetFeeFromRangeDate(moment(start), moment(start).add(2, 'week'))
    } else if (start !== null && end !== null) {
      start = moment(start)
      end = moment(end)
      GetFeeFromRangeDate(start, end)
    }
    setCanReset(false)
    setCanDistributed(false)
  }
  const DeletePaymentItem = (index) => newValue => {
    const oldValue = cuotas[index].value
    const auxArray = []
    if (cuotas.length > 1) {
      cuotas.forEach((obj, ind) => {
        if (ind !== index) {
          auxArray.push(obj)
        }
      })
      auxArray[auxArray.length - 1].value = auxArray[auxArray.length - 1].value + oldValue
      setCuotas(auxArray)
      setCanReset(true)
    } else {
      alert('No es posible eliminar todas las cuotas')
    }
  }
  const EditPaymentItem = (index, percentDiscount) => (newValue, date) => {
    newValue = (parseFloat(newValue) / ((1 - parseFloat(percentDiscount)) * 100)) * 100
    // cantidad total de cuotas no customizadas
    let cTotal = 0
    let total = cuotas.reduce((totalRed, cuota, indx) => {
      if (!cuota.custom && indx !== index) { cTotal += 1 }
      return (indx === index) ? newValue + totalRed : (cuota.custom) ? cuota.value + totalRed : totalRed
    }, 0) // valor total de cuotas customizadas
    let valorCuota = 0
    const totalCuotas = GetTotalPay()
    // se calcula el monto a repartir entre las cuotas no customizadas
    total = totalCuotas - total
    if (cTotal > 0) {
      // valor de las cuotas no customizadas
      valorCuota = total / (cTotal)
    }
    setCuotas((cuo) => {
      return cuo.map((c, indx) => {
        if (index === indx) {
          return { ...c, value: newValue, custom: true, time: date }
        } else if (c.custom) {
          return c
        } else {
          return { ...c, value: valorCuota }
        }
      })
    })
    setCanDistributed(true)
  }
  const AddPaymentItem = (newPayment) => {
    // cantidad total de cuotas no customizadas
    let cTotal = 0
    const total = cuotas.reduce((totalRed, cuota) => {
      if (!cuota.custom) { cTotal += 1 }
      return !cuota.custom ? cuota.value + totalRed : totalRed
    }, 0) // valor total de cuotas no customizadas
    let valorCuota = 0
    if (cTotal > 0) {
      valorCuota = total / (cTotal + 1)
    }
    newPayment = { ...newPayment, value: valorCuota }
    setCuotas(
      cuotas.concat([newPayment]).map((c) => {
        if (c.custom) {
          return c
        } else {
          return { ...c, value: valorCuota }
        }
      })
    )
    setCanReset(true)
  }
  const DistributedPayment = async () => {
    const total = cuotas.reduce((totalRed, cuota) => {
      return cuota.value + totalRed
    }, 0) // valor total de cuotas no customizadas
    const cTotal = cuotas.length
    let valorCuota = 0
    if (cTotal > 0) {
      valorCuota = total / cTotal
    }
    setCuotas(
      cuotas.map((c) => {
        return { ...c, value: valorCuota, custom: false }
      })
    )
    setCanDistributed(false)
  }
  const [firtsTimeInit, setFirtsTimeInit] = useState(true)
  useEffect(() => {
    setForm(
      isEdit
        ? formProps
        : {
          planId: '',
          lpw: { 0: { partname: 'Part 1' } },
          statusPlan: 'draft',
          payments: generatePaymentObject(),
          cuotas: []
        }
    )
    setCuotas(
      isEdit && formProps?.paymentItemsConfig?.length
        ? formProps.paymentItemsConfig.map((cuo) => {
          return {
            value: cuo.amountWithoutDiscount,
            time: cuo.date,
            custom: false
          }
        })
        : []
    )
    setFirtsTimeInit(true)
  }, [formProps, isEdit])
  
  useEffect(() => {
    // si no estas editando o no es la primera vez que se ejecuta initpayment()
    if (!isEdit || !firtsTimeInit) InitPayment()
    setFirtsTimeInit(false)
    // eslint-disable-next-line
  }, [form.lpw, form.typePlan, form.amount, form.typePlanPricing, form.payments])

  useEffect(() => {
    setCuotas(prevCuotas => {
      //ajusta las cuotas mes a mes desde la fecha de inicio de pago
      const start = moment.utc(form.paymentstart).endOf('month').add(-2, 'd')
      return prevCuotas ? prevCuotas.map(cuo => {
        const time = start.clone()
        start.add(1, 'month')
        return {
          ...cuo,
          time: time.toISOString()
        }
      }) : []
    })
  }, [form.paymentstart])
  const parseDataForm = (form, totalLessons) => {
    const parts = []
    let parseEducators = []
    let returnObject = {}
    const totalCost = form.paymentItemsConfig.reduce((totalPayment, pay) => totalPayment + pay.amount, 0)
    const studentRate = form.typePlanPricing === 'perlesson' ? 
      form.amount : 
      (form?.student?.studentInfo?.defaultRate || 75)
    
    Object.keys(form.lpw).forEach((indexLpw) => {
      const lpwTopic = []
      let totalLpw = 0
      Object.keys(form.lpw[indexLpw]).forEach((idTopic) => {
        if (idTopic !== 'startdate' && idTopic !== 'enddate' && idTopic !== 'partname') {
          if (form.typePlan === 'date') {
            if (form.lpw[indexLpw][idTopic].value > 0) {
              lpwTopic.push({
                topicId: idTopic,
                lessonsPerWeek: form.lpw[indexLpw][idTopic].value,
                lockLessons: form.lpw[indexLpw][idTopic].lock ? form.lpw[indexLpw][idTopic].lock : false
              })
              totalLpw += form.lpw[indexLpw][idTopic].value
            }
          } else {
            if (form.lpw[indexLpw][idTopic].value > 0) {
              lpwTopic.push({
                topicId: idTopic,
                lessonsPerWeek: form.lpw[indexLpw][idTopic].value,
                // cuando se reparten un total de lecciones estas no se pueden lockear 
                lockLessons: false
              })
              totalLpw += form.lpw[indexLpw][idTopic].value
            }
          }
          if (form.educators[idTopic]?.length > 0) {
            parseEducators = parseEducators.concat(
              form.educators[idTopic].map(
                ({
                  id,
                  rate, 
                  recommended, 
                  lessonLink, 
                  isWaiveIndividual
                }) => {
              return {
                educatorUserId: id,
                topicId: idTopic,
                rate: parseFloat(rate),
                isLockEducator: recommended || false,
                lessonLink: lessonLink,
                isWaiveIndividual: isWaiveIndividual
              }
            }))
          }
        }
      })
      if (form.typePlan === 'date') {
        parts.push({
          lessonPerWeekPerTopic: lpwTopic,
          lessonsPerWeek: totalLpw,
          totalLessons: TotalLessonPart(parts.length),
          from: form.lpw[indexLpw].startdate,
          to: form.lpw[indexLpw].enddate,
          name: form.lpw[indexLpw].partname
        })
      } else {
        parts.push({
          TotalPerTopic: lpwTopic,
          type: form.typeLesson,
          // totalLessons: totalLpw,
          totalLessons: TotalLessonPart(parts.length),
          from: form.lpw[indexLpw].startdate,
          to: form.lpw[indexLpw].enddate,
          name: form.lpw[indexLpw].partname
        })
      }
    })
    const parseCheckIn = (form.checkInDates || []).map(
      ({type, date}) => {
        return {type, date}
      }
    ) 
    if (form.typePlan === 'date') {
      returnObject = {
        planId: form.planId,
        groupId: form.groupId,
        checkInDates: parseCheckIn,
        amount: form.amount,
        date: form.testdate.date,
        description: '',
        name: form.planname,
        studentUserId: form.student.id,
        rate: studentRate,
        serviceId: form.idservice,
        enforceRecommendedEducators: true,
        testDate: form.testdate.date,
        activityTest: {
          activityId: form.testdate.activityId || null,
          timesSkipped: 0,
        } ,
        parts: parts,
        recommendedEducators: parseEducators,
        paymentItemsConfig: form.paymentItemsConfig,
        statusPlan: form.statusPlan,
        isFirstPlan: form.isFirstPlan || false,
        typePlanPricing: form.typePlanPricing,
        pricingRate: form.pricingRate,
        location: {
          type: form.locationPlan,
          linkStudent: form.locationPlanLink || '',
          linkEducator: form.locationPlanLink || ''
        },
        isAtomic: isAtomic,
        comments: form?.comments
      }
    } else {
      returnObject = {
        planId: form.planId,
        groupId: form.groupId,
        checkInDates: parseCheckIn,
        name: form.planname,
        studentUserId: form.student.id,
        rate: studentRate,
        serviceId: form.idservice,
        enforceRecommendedEducators: true,
        recommendedEducators: parseEducators,
        parts: parts,
        totalCost: totalCost,
        paymentItemsConfig: form.paymentItemsConfig,
        statusPlan: form.statusPlan,
        isFirstPlan: form.isFirstPlan || false,
        typePlanPricing: form.typePlanPricing,
        pricingRate: form.pricingRate,
        location: {
          type: form.locationPlan,
          linkStudent: form.locationPlanLink || '',
          linkEducator: form.locationPlanLink || ''
        },
        isAtomic: isAtomic,
        comments: form?.comments
      }
    }
    return returnObject
  }

  const createPlanAlert = (success, i18nKey) => {
    showAlert({
      text: translate(i18nKey),
      status: success ? "success" : "error"
    }, dispatch)
  }
  const { submit, loading: load } = CreateLessonPerWeekPlan((result) => {
    // onsuccess
    createPlanAlert(true, isDuplicate ? "Successful duplicate plan!" : "modal.newplan.createdLessonPerWeekPlanSuccess")
    refetch()
    onChange()
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.newModal',
      data: { isOpen: !isOpen, formProps: formProps }
    })
  },
  (err) => {
    createPlanAlert(false, err.toString().replace("Error: ", "") || "Error")
    // eslint-disable-next-line
    console.error(err)
  })
  const { submit: submitFixed, loading: loadFixed } = CreateLessonsFixedPlan((result) => {
    // onsuccess
    createPlanAlert(true, isDuplicate ? "Successful duplicate plan!" : "modal.newplan.createdLessonPerWeekPlanSuccess")
    refetch()
    onChange()
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.newModal',
      data: { isOpen: !isOpen, formProps: formProps }
    })
  },
  (err) => {
    createPlanAlert(false, err.toString().replace("Error: ", "") || "Error")
    // eslint-disable-next-line
    console.log(err)
  })

  const { submit: editPlanTest, loading: editLoadTest } = EditLessonsPerWeekPlan((result) => {
    // onsuccess
    refetch()
    onChange()
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.newModal',
      data: { isOpen: !isOpen, formProps: formProps }
    })
  },
  (err) => {
    // eslint-disable-next-line
    console.log(err)
    alert('error')
  })
  const { submit: editPlanFixed, loading: editLoadFixed } = EditFixedPlansService((result) => {
    // onsuccess
    refetch()
    onChange()
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.newModal',
      data: { isOpen: !isOpen, formProps: formProps }
    })
  },
  (err) => {
    // eslint-disable-next-line
    console.log(err)
    alert('error')
  })

  const submitForm = (status, programDate = undefined) => {
    const fee = cuotas.map((cuota, index) => {
      return {
        amount: cuota.value * (1 - (parseFloat(form.payments.discount.lessons || 0) / 100)),
        date: cuota.time,
        description: `Payment ${index}`,
        type: 'lessonsPayment',
        percentDiscount: parseFloat(form.payments.discount.lessons || 0),
        amountWithoutDiscount: cuota.value
      }
    })
    const otherFixed = OtherFixed
    let auxForm = form
    if (auxForm.typePlan === 'time' && auxForm.typeLesson === 'totalLesson') {
      // setea enddate a cada parte
      auxForm = {
        ...auxForm,
        lpw: Object.keys(auxForm.lpw).reduce((obj, index) => {
          return { ...obj, [index]: { ...auxForm.lpw[index], enddate: auxForm.lpw[index].startdate } }
        }, {})
      }
    }
    otherFixed.forEach((obj) => {
      if (auxForm.payments[obj.key]) {

        fee.push({
          amount: auxForm.payments.values[obj.key] * (1 - (parseFloat(auxForm.payments.discount[obj.key] || 0) / 100)),
          date: moment(),
          description: `Payment ${obj.key}`,
          type: 'otherFixed',
          percentDiscount: parseFloat(auxForm.payments.discount[obj.key] || 0),
          amountWithoutDiscount: auxForm.payments.values[obj.key],
          typeFixed: TYPEFIXED[obj.key] || TYPEFIXED.nothing
        })
        
      }
    })
    if(incomplete) {
      refetch(
        {
          ...auxForm,
          statusPlan: status,
          typeLesson: form.typeLesson ? form.typeLesson : '',
          paymentItemsConfig: fee,
          pricingRate: form.amount
        }
      )
      onChange()
      //cierra el modal
      toggle()
    }
    else { 
      if (isEdit && !isDuplicate && !isTimelinePlan) {
        if (form.typePlan === 'date') {
          editPlanTest(parseDataForm(
            {
              ...auxForm,
              typeLesson: form.typeLesson ? form.typeLesson : '',
              paymentItemsConfig: fee,
              pricingRate: form.amount,
              groupId: form.groupId
            }, TotalLesson()
          ))
        } else {
          editPlanFixed(parseDataForm(
            {
              ...auxForm,
              paymentItemsConfig: fee,
              pricingRate: form.amount,
              groupId: form.groupId
            }, TotalLesson()
          )
          )
        }
      } else {
        let timelineObj = {}

        if(isTimelinePlan && timelinePlanId && timelineId) {
          timelineObj.timelinePlanId = timelinePlanId
          timelineObj.timelineId = timelineId
        }
        let programObj = {}
        if(programDate) {
          programObj = {...programObj, sendDate: new Date(programDate)}
        }
        if (form.typePlan === 'date') {
          const parseObj = parseDataForm(
            {
              ...auxForm,
              statusPlan: status,
              typeLesson: form.typeLesson ? form.typeLesson : '',
              paymentItemsConfig: fee,
              pricingRate: form.amount
            }, TotalLesson()
          )
          submit({...parseObj, ...timelineObj, ...programObj})
        } else {
          const parseObj = parseDataForm(
            {
              ...auxForm,
              statusPlan: status,
              typeLesson: form.typeLesson ? form.typeLesson : '',
              paymentItemsConfig: fee,
              pricingRate: form.amount
            }, TotalLesson()
          )
          submitFixed({...parseObj, ...timelineObj, ...programObj})
        }
      }
    }
    
  }

  const validation = useMemo(() => {
    //sección estudiante
    if(!form?.student) return false
    //sección plan details
    if(
      !form?.typePlan || 
      !form?.idservice || 
      !(form?.planname?.length > 0) ||
      !form?.locationPlan
    ) return false    
    else if (
      form?.typePlan === 'date' && !form?.testdate
    ) return false
    else if(
      form?.locationPlan === "inPerson" && !(form?.locationPlanLink?.length > 0)
    ) return false
    //sección scheduled details
    let topics = []
    let parts = Object.entries(form?.lpw)
    for(let p=0; p < parts.length; p++){
      let [, part] = parts[p]
      let topicFromPart = []
      if(
        !(part?.partname?.length > 0) || 
        !part?.startdate
      ) return false
      else if (
        form?.typePlan === 'date' && !part?.enddate
      ) return false
      let topicsIds = Object.keys(part).reduce((topics, key) => {
        if(!["partname", "startdate", "enddate"].includes(key)) topics.push(key)
        return topics
      }, [])
      for(let t=0; t < topicsIds.length; t++){
        if(part[topicsIds[t]].value > 0) topicFromPart.push(topicsIds[t])
      }
      if(topicFromPart.length === 0) return false //no hay tópicos seleccionados para esta parte
      topics = topics.concat(topicFromPart)
    }

    //sección check-ins
    if(form?.checkInDates?.length > 0) {
      const datesNulls = form.checkInDates.reduce((bool, {date}) => bool || !date, false)
      if(datesNulls) return false
    }

    //sección educadores
    topics = topics.reduce((topicDontRepeat, idTopic) => {
      if(!topicDontRepeat.includes(idTopic)) topicDontRepeat.push(idTopic)
      return topicDontRepeat
    }, [])
    if(!form?.educators) return false
    else {
      for(let t=0; t < topics.length; t++){
        let idTopic = topics[t]
        if(!form.educators[idTopic]) return false
        else if(form.educators[idTopic]?.length === 0) return false //ho hay educadores seleccionados para el tópico
        else {
          let rateZero = form.educators[idTopic].reduce((bool, edu) => {
              const {rate, isWaiveIndividual} = edu
              return (parseInt(rate) === 0 && !isWaiveIndividual) || bool  
            }, false) 
          if(rateZero) return false
        }
      }
    }
    //sección pricing
    if(
      !form?.typePlanPricing || !(parseInt(form?.amount || 0) > 0)
    ) return false
    return true
  }, [form])
  return (
    <>
      <div className='m-0 p-0' id={'createLivePrepPlanModalId'} onClick={toggle}>
        {props.children}
      </div>
      <Modal
        className='modal-sidebar modal-colored scheduled-modal modal-desktop-half'
        isOpen={isOpen}
        toggle={toggle}
        backdrop='static'
        Head={
          <div className='modal-header p-0 m-0' style={styleBubbles}>
            <div className='d-flex justify-content-between w-100 p-7'>
              <h2 className='font-weight-bold'>
                {
                  isTimelinePlan ? 'Create timeline plan' : isDuplicate ? 'Duplicate Plan' : isEdit ? translate('plans.modal.editplan') : translate('plans.modal.newplan')
                }
              </h2>
              <div className={`hover-icon`}>
                <IconClose onClick={toggle} />
              </div>
            </div>
          </div>
        }
      >
        <Form value={form} onChange={setForm}>
          <div className='m-0 p-0  mb-2 ml-6'>
            {
              !incomplete && (<PlanStudent form={form} setForm={setForm} />) 
            }
            <PlanDetails
              form={form}
              setForm={setForm}
              data={data}
              isEdit={isEdit}
              isTimelinePlan={isTimelinePlan}
            />
            <ScheduleDetails
              setForm={setForm}
              form={form}
              currentService={currentService}
            />
            <CheckIns
              setForm={setForm}
              form={form}
              isEdit={isEdit}
            />
            <EducatorsPlan
              form={form}
              setForm={setForm}
              currentService={currentService}
              topicsToShow={
                JSON.stringify(Object.values(form.lpw)) !== JSON.stringify([{}])
                  ? Object.values(form.lpw).map((partObj) => {
                    return Object.keys(partObj).reduce((arrayAccu, idTopic) => {
                      if (partObj[idTopic].value > 0) {
                        return arrayAccu.concat([idTopic])
                      } else {
                        return arrayAccu
                      }
                    }, [])
                  }).reduce((array, arrayItem) => {
                    return array.concat(arrayItem)
                  }, [])
                  : []
              }
              educators={educators || []}
            />
            <PricingPlan
              dontInitPayment={() => { setFirtsTimeInit(true) }} TotalLesson={TotalLesson} form={form} setForm={setForm} lengthCuotas={
                GetTotalPay()
              }
            />
            <PaymentSchedule
              form={form}
              cuotas={cuotas}
              totalPay={form.payments.lessons ? GetTotalPay() : 0}
              setCuotas={setCuotas}
              InitPayment={InitPayment}
              EditPaymentItem={EditPaymentItem}
              AddPaymentItem={AddPaymentItem}
              DistributedPayment={DistributedPayment}
              DeletePaymentItem={DeletePaymentItem}
              canReset={canReset}
              canDistributed={canDistributed}
            />

            <label onClick={openAddComments} className='pt-2 m-0 btn-link'>
              {form?.comments ? translate('modal.newplan.viewcomments') : translate('modal.newplan.addcomments')}
            </label>
            <AddComments
              form={form}
              setForm={setForm}
              isEdit
            />
            <div className="form-check pt-5">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isAtomic}
                onChange={(e) => setIsAtomic(e.target.checked)}
                id="isAtomicCheckbox"
              />
              <label className="form-check-label" htmlFor="isAtomicCheckbox">
                From Atomic plan
              </label>
            </div>

            <StickyButton>
              <div className='pl-0 pr-6 py-2 pl-3 my-3'>
                <ButtonOptions
                  useOptions={!(isEdit || incomplete)}
                  color='primary'
                  activity={load || loadFixed || editLoadFixed || editLoadTest}
                  className={'w-100'
                    // isEdit || incomplete ? 'col-11' : 'col-9'
                  }
                  label={isTimelinePlan ? 'Create timeline plan' : isDuplicate ? 'Duplicate Plan' : isEdit ? translate('plans.modal.save') : translate('plans.modal.saveindraft')}
                  onClick={() => {
                    submitForm(PlanStatus.draft)
                  }}
                  disabled={!validation && !incomplete}
                  options={[{
                    component:
                      <DropDownItem
                        onClick={() => {submitForm(PlanStatus.sent)}}
                      >{translate('plans.modal.saveinsent')}</DropDownItem>
                  },
                  {component: (
                    <DropDownSub title={translate('plans.modal.sendplan.programSentPlan.option')}>
                      <ProgramSentPlan
                        onProgram={(programDate) => {
                          submitForm(PlanStatus.draft, programDate)
                        }}
                      />
                    </DropDownSub>
                  )}
                ]}
                />
              </div>
            </StickyButton>
          </div>
        </Form>
      </Modal>
    </>
  )
}
export default NewPlan
