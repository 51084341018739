import React, {useEffect, useMemo, useState, useRef} from 'react'
import ActualUser from './components/ActualUser'
import ChatBox from './components/ChatBox'
import {useSelector} from 'react-redux'
import NoHaveMessages from './components/NoHaveMessages'
import {createOnlineStatus} from './helpers/createOnlineStatus'
import './style.css'
import SendMessage from './components/SendMessage'
import NotSendMessage from './components/NotSendMessage'
import {NewMessageBadge} from '@Knowledge-OTP/znk-ui-components'

const VirtualChat = (props) => {
  // eslint-disable-next-line
  const { 
    active = false, collectionType, usersList, 
    collection, actualUserId = '', dataPlan, 
    staffCanRead = false, onBack = () => {}, 
  } = props

  const planId = dataPlan?.id
  const eventID = collection?.taskId || collection?.id
  const userInfo = useSelector((state) => state.user)
  const roles = userInfo?.roles
  const adminID = userInfo?.id
  const userID = actualUserId
  const [loading, setLoading] = useState(false)
  const finishTime = collection.status === 'completed'

  const isAdmin = roles.includes('SUPER_ADMIN')
  // const isPartOfTeam = false
  const [isPartOfTeam, setIsPartOfTeam] = useState(false)
  const {attendanceStatus} = useMemo(() => {
    if ( ['UserTasksOut', 'UserTasks', 'Task'].includes(collection?.__typename) )
      return { attendanceStatus: 'attended'}
    return {}
    // eslint-disable-next-line
  }, [collection, userID, isAdmin])

  useEffect(() => {
    let userFound = []
    if(usersList?.usersList?.length > 0 && adminID){
      userFound = usersList?.usersList?.find(user => user.id === adminID)
    }
    setIsPartOfTeam(userFound)
  }, [usersList, adminID])
  

  const handleLoadPage = (event) => {
    event.preventDefault()
    createOnlineStatus(userID, setLoading, false, eventID).then(() => {
      // setLoading(false)
    })
  }
  useEffect(() => {
    if (!finishTime) {
      createOnlineStatus(userID, setLoading, true, eventID).then(() => {})
      window.addEventListener('beforeunload', handleLoadPage)
    }
    return () => {
      createOnlineStatus(userID, setLoading, false, eventID).then((resp) =>
        {
          console.log(resp)
        }
      )
      window.removeEventListener('beforeunload', handleLoadPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, eventID])



  const myRef = useRef(null);

  const scrollToRef = (behavior = 'smooth') => {
    if(myRef?.current?.parentElement) {
      myRef.current.parentElement.scrollIntoView({ behavior, block: 'end' });
    }
  }

  useEffect(() => {
    if(active) scrollToRef('auto')
  }, [active])
  
  const showChat = (staffCanRead && isAdmin) || (collection?.id && attendanceStatus === 'attended')
  
  
  const [openBagde, setOpenBagde] = useState(false)
  const onNewMessageRecieved = (messages) => {
    if(!active) return
    const messagesList = (messages?.messages || [])
    const lastMessage = messagesList?.length > 0 && messagesList[messagesList.length-1]
    const {userID: userIdMessage, readed = {}} = lastMessage
    if(userID === userIdMessage || readed[userID]?.readed) return //no hace nada
    else if(myRef?.current) {
      const offset =  (myRef.current.getBoundingClientRect()?.bottom)
      if(offset >= window.innerHeight+150) {
        setOpenBagde(true)
      }
      else {
        setTimeout(() => {scrollToRef('auto')}, 1000)
      }
    }
    
  }
  return (
    // isPartOfTeam ? 
    <>
      <ActualUser
        backArrow={onBack}
        usersOnChat={usersList?.usersList}
        collection={collection}
        loading={loading}
      />
      {showChat && (
        <div style={{flexGrow: 1}} className='py-3' ref={myRef}>
          <ChatBox {...collection} onNewMessage={onNewMessageRecieved} isActive={active} usersList={usersList?.usersList ? usersList?.usersList : usersList} />
        </div>
      )}
      {!showChat && <div style={{flexGrow: 1}} className='p-0 m-0'><NoHaveMessages {...collection} /></div>}
      {(staffCanRead || isAdmin) || isPartOfTeam ? 
      <SendMessage
        onSend={(inputRef) => {
          if(inputRef?.current) inputRef.current.focus()
          scrollToRef()
        }}
        collection={collection}
        attendanceStatus={attendanceStatus}
        collectionType={collectionType[collection?.__typename]}
        planId={planId}
        staffCanWrite={staffCanRead && isAdmin}
      /> 
      :
      <NotSendMessage /> 
      }

      <div className={`new-message-content ${openBagde ? 'open' : 'close'}-new-message`}>
        <NewMessageBadge
          onClick={() => {
            scrollToRef()
            setOpenBagde(false)
          }}
          className={`new-message`}
        >{`New message(s)`}</NewMessageBadge>
      </div>
    </> 
    // : <NoHaveMessages noPermission={!isPartOfTeam}/>
  )
}

export default VirtualChat
