import useSubmitScoreModal from "../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/useSubmitScoreModal";
import { showAlert } from "../../../../../../common/Alert/util";
import { useDispatch } from "react-redux";
import useUpdateScore from "../../../../../Workspace/Model/useUpdateScore";
import useAddNewScore from "../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/Model/useAddNewScore";

//indica a que modelo se le adjutará el score
export const FORMODEL = {
  task: "task",
  lessonPerWeekPlan: "lessonPerWeekPlan",
  plan: "plan",
  student: "student",
};

const useHandlerExamForm = (refetch) => {
  const dispatch = useDispatch();
  const onCompleted = () => {
    showAlert({ text: "Success", status: "success" }, dispatch);
    setLoading(false);
    close();
    refetch(true);
  };

  const onError = (error) => {
    showAlert({ text: error.message || "Error!", status: "error" }, dispatch);
    setLoading(false);
  };

  const [setUpdateScore] = useUpdateScore(onCompleted, onError);
  const [addNewScore] = useAddNewScore(onCompleted, onError);

  const reduxData = useSubmitScoreModal();
  const setData = (studentId) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.infoStudentModal',
      data: {
        ...reduxData,
        dataStudent: {
          id: studentId
        }
      }
    })
  }
  const {
    setLoading = () => {},
    close,
    openForView,
    openForCreate,
  } = reduxData;

  const updateScore = async (examScore = {}) => {
    setLoading(true);
    const variables = {
      examScore,
    };
    try {
      await setUpdateScore({ variables });
    } catch (e) {
      onError(e.message);
    } finally {
      setLoading(false);
    }
    // return setUpdateScore({ variables });
  };

  const createScore = async (examInput = {}, plan = {}, planId) => {
    // si no hay student id, que siga igual. Si hay, hay que buscar todos los planes del estudiante
    setLoading(true);
    const variables = {
      examScore: examInput,
      //TODO: revisar ese ID
      planId: planId,
    };
    return addNewScore({ variables });
  };

  const openScoreFormEdit = (
    examData = {},
    showPlansToAdd,
    onChange = () => {}
  ) => {
    setData(examData?.studentId);
    openForView(
      (examInput) => {
        setLoading(true);
        updateScore(examInput)
          .then(() => {
            onChange(examInput, examInput?.planId);
          })
          .catch(onError)
          .finally(() => setLoading(false));
      },
      examData,
      showPlansToAdd
    );
  };

  const openModalScoreForCreate = (
    onChange = () => {},
    examData,
    showPlanData
  ) => {
    openForCreate(
      (examInput) => {
        createScore(examInput)
          .then(() => {
            onChange(examInput, examInput?.planId);
          })
          .catch(onError)
          .finally(() => setLoading(false));
      },
      examData,
      true,
      showPlanData
    );
  };

  const open = async (examData = {}, showPlanData, onChange = () => {}) => {
    if (examData?.id) {
      return openScoreFormEdit(examData, showPlanData, onChange);
    } else if (!examData) {
      openModalScoreForCreate(() => {}, examData, [], null);
    } else if (examData?.studentId) {
      setData(examData?.studentId);
      openModalScoreForCreate(() => {}, examData, showPlanData);
    }
  };

  return {
    ...reduxData,
    open,
  };
};

export default useHandlerExamForm;
