import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation create(
        $name: String!,
        $studentUserId: ID!,
        $rate: Float,
        $serviceId: ID!,
        $statusPlan: PlanStatus,
        $enforceRecommendedEducators: Boolean!,
        $isFirstPlan: Boolean!,
        $typePlanPricing: TypePlanPricing!,
        $pricingRate: Float,
        $recommendedEducators: [PlanEducatorInput!]!,
        $paymentItemsConfig: [PaymentsItemsConfigInput!],
        $parts: [TimelessPlanPartInput!]!,
        $totalCost: Float!,
        $location: LocationLessonInput!,
        $activityTest: ActivityTestInput
        $checkInDates: [CheckInDateInput!] = []
        $sendDate: DateTime,
        $isAtomic: Boolean
        $comments: String
    ){
        createLessonsFixedPlan(
        input: {
          name: $name,
          statusPlan: $statusPlan,
          checkInDates: $checkInDates,
          studentUser: {
            studentUserId: $studentUserId,
            rate: $rate
          },
          isAtomic: $isAtomic
          comments: $comments
          serviceId: $serviceId,
          recommendedEducators: $recommendedEducators,
          enforceRecommendedEducators: $enforceRecommendedEducators,
          paymentItemsConfig: $paymentItemsConfig,
          parts: $parts,
          totalCost: $totalCost,
          location: $location,
          isFirstPlan: $isFirstPlan,
          typePlanPricing: $typePlanPricing,
          pricingRate: $pricingRate,
          activityTest: $activityTest
          sendDate: $sendDate
        },
      ){id, studentUser{firstName, lastName}}
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.createLessonsFixedPlan : null, error, validationErrors, submit }
}
