import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Avatar,
  IconLesson,
  IconOptions,
  ListItem,
  Stats,
  useWindowDimensions,
  IconChecboxSelected,
  PaymentProgress,
  IconArrowRight, 
  BulletPoint
} from '@Knowledge-OTP/znk-ui-components'
import { gql, useLazyQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../../i18n/useTranslation'
import { upperCase } from 'lodash/string'
import moment from 'moment-timezone'
import startCase from 'lodash/startCase'
import ChildPlanPricing from './ChildPlan/Pricing'
import ChildPlanPaymentSchedule from './ChildPlan/PaymentSchedule'
import formatNumber from '../../../../../utils/formatNumber'
import CheckPendingOrientation from '../Components/CheckPendingOrientation'
import CheckInSection from './Sections/CheckInSection'
import AddComments from '../../newPlan/Components/AddComments'

const Resume = (props) => {
  const { lessons, lessonsLoading, info, topicKeys, groupInfo, groupLessons, groupLoading } = props
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const dispatch = useDispatch()
  
  const [getPayments, { data: paymentsQuery }] = useLazyQuery(
    gql`
        query($planId: String!) {
            myPaymentsItems(planId: $planId) {
                docs {
                    amount
                    status
                    description
                    category
                    type
                    date
                }
            }
        }
    `
  )
  useEffect(() => {
    if (info.id && info.id !== '') {
      getPayments(
        {
          variables: { planId: info.id }
        })
    }
  }, [getPayments, info])

  let pendingTotal = 0
  let paidTotal = 0
  let total = 0
  let countPending = 0
  let countPaid = 0

  if (paymentsQuery && paymentsQuery.myPaymentsItems && paymentsQuery.myPaymentsItems.docs) {
    paymentsQuery.myPaymentsItems.docs.forEach((data) => {
      if (data.type !== 'otherFixed') {
        if (data.status === 'pendingPayment' || data.status === 'unPaid') {
          countPending++
          pendingTotal += data.amount
        }
        if (data.status === 'paid') {
          paidTotal += data.amount
          countPaid++
        }
      }
    })
    total = pendingTotal + paidTotal
  }

  const dataProgress = {
    pendingTotal,
    paidTotal,
    countPending,
    countPaid
  }

  const RenderPaymentScheduled = ({ info, items }) => {
    return (
      <div className='pt-0 pb-4'>
        {items.map((item, index) => {
          return (
            <ListItem
              className='py-1'
              Left={
                (item.status === 'canceled')
                  ? <div className='d-flex'>
                    <span className={`${info.status === 'pause' ? 'text-gray' : ''}`} style={info.status === 'cancel' ? { textDecoration: 'line-through' } : {}}>
                      {
                        info.status === 'pause'
                          ? translate('program.plan.paused')
                          : translate('profile.payments.endOf', moment(item.date).format('MMMM'))
                      }
                    </span>
                    <span className='ml-3 h45 text-gray' style={info.status === 'cancel' ? { textDecoration: 'line-through' } : {}}>
                      {`(${translate('modal.newplan.indexFee', {
                            num1: index + 1,
                            num2: items.length
                          })})`}
                    </span>
                    </div>
                  : <div className='d-flex'>
                    <span>
                      {translate('profile.payments.endOf', moment(item.date).format('MMMM'))}
                    </span>
                    <span className='ml-3 h45 text-gray'>
                      {
                        info.status === 'cancel'
                          ? `(${translate('plans.modal.cancelplan.remaining')})`
                          : `(${translate('modal.newplan.indexFee', {
                            num1: index + 1,
                            num2: items.length
                          })})`
                      }
                    </span>
                    </div>
              }
              Right={
                (item.status === 'canceled')
                  ? <div className='text-gray' style={info.status === 'cancel' ? { textDecoration: 'line-through' } : {}}>${formatNumber(item.amount)} <IconArrowRight /></div>
                  : <div>${formatNumber(item.amount)} <IconArrowRight /></div>
              }
            />
          )
        })}
      </div>
    )
  }
  //const planParts = (info.parts || info.fixedParts || info.PartsSchedule)
  const totalGroupLesson = groupInfo?.PartsSchedule.reduce((total, part) => {
    return part.lessonsPerWeekGroup.reduce((sum, item) => item.totalLessons + sum, 0) + total
  }, 0) || 0
  const otherFixedPayment = info.paymentItemsConfig.filter((pay) => pay.type === 'others-fixed')
  const paymentsPauseAndCancel = paymentsQuery ? paymentsQuery.myPaymentsItems.docs.filter((pay) => pay.type !== 'otherFixed').reverse() : []
  const paymentsNames = {
    'Payment registration': translate('modal.newplan.registrationfee'),
    'Payment exam': translate('modal.newplan.examregistrationfee'),
    'Payment pro': 'Marathon'
  }
  const studentInfo = useMemo(() => {
    return info?.getStudentInfo
  }, [info])

  const lessonsArray = useMemo(() => {
    return (lessons || []).filter(({status}) => ["scheduled", "pending", "finished"].includes(status.toLowerCase()))
  }, [lessons])
  //total de lecciones individuales
  const totalIndividualLesson = lessonsArray.length
  //planParts.reduce((total, part, idx) => {
  //  return part.totalLessons + total
  //}, 0)

  const openAddComments = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.addComments',
      data: { isOpen: true }
    })
  }
  return (
    <div className='d-flex flex-column'>
      {
        (info.status === 'pause' || info.status === 'cancel') &&
        <>
          <div className='borderTop forceOverflow my-2' />
          <div className='py-2 h45 d-flex align-items-center'>
            <span className={`mx-0 mr-2 mx-xl-0 ml-xl-2 badge badge-pill point badge-${info.status === 'pause' ? 'primary' : 'cancel'}`}>&nbsp;</span>
            <span className='text-muted ml-2'>
              {
                info.status === 'pause'
                  ? `${translate('program.plan.paused')} ${moment(info.pauseDate || '2020-04-04 11:45:26.123').fromNow()}`
                  : `${translate('program.plan.canceled')} ${moment(info.cancelDate || '2020-04-04 11:45:26.123').fromNow()}`
              }
            </span>
          </div>
        </>
      }
      <CheckPendingOrientation planId={info?.id} />
      <div className='borderTop forceOverflow my-2' />
      <div className='pb-2'>
        <span className='py-3 font-weight-bold text-dark'>{translate('program.plan.student')}</span>
        <ListItem
          className='py-2'
          Left={
            <div className='d-flex align-items-center text-body'>
              {breakWidth !== 'SM' ? (
                <Avatar
                  name={`${info.studentUser.firstName} ${info.studentUser.lastName}`}
                  named={false}
                  uri={info?.getStudentInfo?.avatar || null}
                  size='avatar-medium'
                />
              ) : null}
              <span className='ml-2'>
                {`${info.studentUser.firstName} ${info.studentUser.lastName}`}
                <span style={{ fontSize: 14 }} className='font-weight-light ml-2 text-gray'>
                  ({translate('program.plan.student')})
                </span>
              </span>
            </div>
          }
          Right={<IconOptions size={16} style={{ transform: 'rotate(90deg)' }} />}
        />
        {
          studentInfo?.studentType === 'family' && studentInfo?.parents?.length > 0 && (
            studentInfo?.parents?.filter((parent) => parent?.manageApprovals || parent?.managePayments).map((parent) => {
              return (
                <ListItem
                  className='py-2'
                  Left={
                    <div className='d-flex align-items-center text-body'>
                      {breakWidth !== 'SM' ? (
                        <Avatar
                          name={`${parent.firstName} ${parent.lastName}`}
                          named={false}
                          uri={info?.getStudentInfo?.avatarParent || null}
                          size='avatar-medium'
                        />
                      ) : null}
                      <span className='ml-2'>
                        {`${parent.firstName} ${parent.lastName}`}
                        <span style={{ fontSize: 14 }} className='font-weight-light ml-2 text-gray'>
                          ({translate('modal.newplan.parent')})
                        </span>
                      </span>
                    </div>
                  }
                  Right={<IconOptions size={16} style={{ transform: 'rotate(90deg)' }} />}
                />
              )
            })
          )
        }
        {/**parentQuery?.user?.parentInfo ? (
          <ListItem
            className='py-2'
            Left={
              <div className='d-flex align-items-center text-body'>
                <span>
                  {parentName}
                  <span style={{ fontSize: 14 }} className='font-weight-light ml-2 text-gray'>
                    (Parent)
                  </span>
                </span>
              </div>
            }
            Right={<IconOptions size={16} style={{ transform: 'rotate(90deg)' }} />}
          />
          ) : null**/}
      </div>
      <div className='borderTop forceOverflow my-2' />
      <span className='font-weight-bold text-dark pt-3 pb-2'>
        {translate('program.plan.progress')}
      </span>
      <div className='align-self-center text-body'>
        {lessonsLoading ? <Skeleton height={200} /> : <Stats items={lessonsArray} />}
      </div>
      <div className='borderTop forceOverflow my-2' />
      {
        groupInfo && (
          <>
            <span className='font-weight-bold text-dark pt-3 pb-2'>
              {translate('program.plan.groupProgress')}
            </span>
            <div className='align-self-center text-body'>
              <Stats items={groupLessons} loading={groupLoading} />
            </div>
            <div className='borderTop forceOverflow my-2' />
          </>
        )
      }
      <span className='font-weight-bold text-dark py-3'>
        {translate('program.plan.weeklySchedule')}
      </span>
      {info.parts.map((part, idx) => {
        const groupPart = groupInfo?.PartsSchedule[idx]
        const groupLessonPerWeekPart = groupPart ? groupPart.lessonsPerWeekGroup.reduce((topicObj, groupBlock) => {
          const blockMoment = moment(moment.utc({ hour: groupBlock.hour }).day(groupBlock.day).toISOString())
          if (topicObj[groupBlock.topicId]) {
            const prevInfo = topicObj[groupBlock.topicId]
            topicObj[groupBlock.topicId] = {
              ...prevInfo,
              blocks: prevInfo.blocks.concat([{
                day: blockMoment.format('dddd'),
                hour: blockMoment.format('hha')
              }]),
              lessonsPerWeek: prevInfo.lessonsPerWeek + 1,
              totalLessons: prevInfo.totalLessons + groupBlock.totalLessons // se suma para le mismo topico
            }
          } else {
            topicObj[groupBlock.topicId] = {
              blocks: [{
                day: blockMoment.format('dddd'),
                hour: blockMoment.format('hha')
              }],
              topicId: groupBlock.topicId,
              lessonsPerWeek: 1,
              totalLessons: groupBlock.totalLessons || 1
            }
          }
          return topicObj
        }, {}) : {}
        const parts = part.lessonPerWeekPerTopic || part.TotalPerTopic
        return (
          <div key={idx} className='mb-2 d-flex flex-column text-body'>
            <span className='h5 my-2 text-gray font-weight-light'>
              {upperCase(
                translate('program.plan.infoModal.partDuration', {
                  from: moment(part.from.split('T')[0]).format('MMMM Do'),
                  to: moment(part.to.split('T')[0]).format('MMMM Do')
                })
              )}
            </span>
            {
              Object.values(groupLessonPerWeekPart).map((topicPart) => {
                return (
                  <ListItem
                    className='py-2'
                    Left={
                      <div className='d-flex align-items-center text-body'>
                        <span>
                          <IconLesson className='mr-2 text-logo' />
                          {
                            translate('modal.draftplan.groupalLesson', {
                              num: topicPart.lessonsPerWeek,
                              topic: topicKeys[topicPart.topicId]?.name
                            })
                          }
                        </span>
                        <BulletPoint
                          direction='left'
                          innerComponent={
                            <>
                              {topicPart.blocks.map(({ day, hour }) => <span className='d-flex align-items-center'>{day}<span className='mx-2 flex-grow-1' />{hour}</span>)}
                            </>
                          }
                          theme='dark'
                        >
                          <span className='text-gray ml-2'>
                            ({topicPart.totalLessons})
                          </span>
                        </BulletPoint>
                      </div>
                    }
                    Right={
                      <></>
                    }
                  />
                )
              })
            }
            {parts.map((lpw) => {
              let totalLessons = lessonsArray.filter(({status}) => ["scheduled", "pending", "finished"].includes(status.toLowerCase()))
                .filter((les) => les.part === idx)//filtro por parte
                .reduce((arrayByTopic, lesson ) => { //filtro por tópico
                  let {topic} = lesson
                  if(topic.id === lpw.topicId) arrayByTopic.push(lesson)
                  return arrayByTopic
                }, []).length
              return (
                <span key={`${lpw.topicId}-${idx}`}>
                  <ListItem
                    className='py-2'
                    Left={
                      <div className='d-flex align-items-center text-body'>
                        <IconLesson size={16} className='mr-2 text-logo' />
                        {translate(`program.plan.infoModal.${lpw.type && lpw.type === 'totalLesson' ? 'total' : 'individual'}TopicLessons`, {
                          num: lpw.lessonsPerWeek,
                          topic: topicKeys[lpw.topicId]?.name || '-'
                        })}
                        <span className='text-gray ml-2'>
                          ({totalLessons})
                        </span>
                      </div>
                    }
                    Right={
                      <></>
                    }
                  />
                </span>
              )
            })}
          </div>
        )
      })}
      <div className='borderTop forceOverflow my-2' />
      <>
        <span className='font-weight-bold text-dark py-3'>
          {translate("program.plan.infoModal.checkInSectionResume")}
        </span>
        <CheckInSection plan={info} />
      </>
      <div className='borderTop forceOverflow my-2' />
      <span className='font-weight-bold text-dark py-3'>
        {translate('program.plan.infoModal.educators')}
      </span>
      {info.recommendedEducators.map((item) => {
        const avatarUrl = info?.aditionalInfo?.educatorsAvatar?.find(({educatorUserId}) => educatorUserId === item.educatorUserId)?.avatar || null
        return (
          <ListItem
            key={`${item.educatorUserId}-${item.topicId}`}
            className='py-2'
            Left={
              <div className='d-flex align-items-center text-body'>
                {breakWidth !== 'SM' ? (
                  <Avatar
                    className='mr-2'
                    name={`${item.firstName} ${item.lastName}`}
                    named={false}
                    uri={avatarUrl}
                    size='avatar-medium'
                  />
                ) : null}
                <span>
                  {`${item.firstName} ${item.lastName}`}
                  <span style={{ fontSize: 14 }} className='font-weight-light ml-2 text-gray'>
                    ({topicKeys[item.topicId]?.name}, {startCase(item.teachingType)})
                  </span>
                </span>
              </div>
            }
            Right={
              <></>
            }
          />
        )
      })}
      <div className='borderTop forceOverflow my-2' />
      {
        info?.groupId ? (
          <>
            <ChildPlanPricing {...{ info, totalGroupLesson, totalIndividualLesson, paymentsNames }} />
            <div className='borderTop forceOverflow my-2' />
            <ChildPlanPaymentSchedule info={info} />
          </>
        ) : (
          (info.status === 'pause' || info.status === 'cancel')
            ? (
              <>
                <span className='font-weight-bold text-dark py-3'>
                  {translate('modal.newplan.paymentschedule.title')}
                </span>
                <RenderPaymentScheduled items={paymentsPauseAndCancel} info={info} />
              </>
              )
            : <>
                <span className='font-weight-bold text-dark py-3'>
                  {translate('program.plan.breakdown')}
                </span>
                <div className='pb-3'>
                  {otherFixedPayment.map((item) => {
                    return (
                      <ListItem
                        className='py-1'
                        Left={
                          <div className='d-flex align-items-center text-body'>
                            <IconChecboxSelected className='mr-3' style={{ borderRadius: 12, color: '#696CFF' }} />
                            {paymentsNames[item.description] || item.description}
                          </div>
                        }
                        Right={<div>{`$${item.amount}`}</div>}
                      />
                    )
                  })}
                  <ListItem
                    className='py-1'
                    Left={
                      <div className='d-flex align-items-center text-body' style={{whiteSpace: 'nowrap'}}>
                        <IconChecboxSelected className='mr-3' style={{ borderRadius: 12, color: '#696CFF' }} />
                        <span>{`${totalIndividualLesson} ${totalIndividualLesson > 1 ? translate('modal.infoplan.individuallessons').toLowerCase() : translate('modal.infoplan.lesson').toLowerCase()}`}</span>
                      </div>
                    }
                    Right={
                      <div style={{whiteSpace: 'nowrap'}} >
                        ${info.typePlanPricing !== 'permonth' ? `${info.pricingRate.toFixed(2)} per Lesson` : info.pricingRate.toFixed(2)}
                      </div>
                      }
                  />
                </div>
                <span className='font-weight-bold text-dark pt-5 pb-4'>
                  {translate('program.plan.paymentStatus')}
                </span>
                <PaymentProgress
                  data={dataProgress}
                  progressColor='#A7E521'
                  bgColor='#FF3E1D'
                  completed={(paidTotal * 100) / total}
                />
                <div class="pt-3 m-3 fs-3">
                  <label className='btn-link' onClick={openAddComments}>
                   {translate('modal.newplan.viewcomments')}
                  </label>
                </div>
                <AddComments
                  form={info}
                  setForm={() => {}}
                  idEdit={false}
                />
              </>
        )
      }
    </div>
  )
}

export default Resume
